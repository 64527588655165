import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accessTokenInfoRequest, tenantInfoRequest } from '../../../redux/actions/accessToken'
// import { myOrganisationInfoRequest } from '../../../redux/actions/myOganisation'
import global from '../../../redux/constants/global'
// import { RootState } from '../../../redux/reducers'
import LoadingPage from '../../utils/loadingPage'
import NotificationToast from '../../utils/notifToast'
import { authorizationRedirect } from '../../../redux/constants/api'
// import global from '../../constants/global'

export default function AuthWrapper(props: any) {
    const [tokenLoading, setTokenLoading] = useState(true)
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')

    // const accessTokenState = useSelector((state: RootState) => state.accessTokenInfoReducer)

    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(window.location.search)

    useEffect(() => {

        const session = queryParams.get("session") || ""
        const email = queryParams.get("email") || ""
        const loggedOut = queryParams.get("loggedOut") || ""

        if (loggedOut === "true") {
            authorizationRedirect()
        }
        if (!session) {
            setTokenLoading(false)
        }
        else {
            getLatestToken(email, session)
        }

        // if(tokenLoading === true){
            getTenantInfo()
        // }

    }, []);

    let getLatestToken = (email: string, session: string) => {
        setTokenLoading(true)
        const callback = (data: any) => {
            if (data?.status) {
                queryParams.delete('session')
                queryParams.delete('email')
                Cookies.set('babtbu', data?.data?.token, { secure: true })
                Cookies.set("org", data?.data?.organisation[0]?.organisation?.id, { secure: true })

                // setTokenLoading(false)
                window.location.href = global.appBaseUrl
            } else {
                setNotifTitle("Error")
                setNotif(data?.data)
                setNotifVal(true)
                setTokenLoading(false)
                // window.location.href = global.appBaseUrl + "login"
            }
        }
        let data = {
            values: {
                email,
                session,
            },
            callback,
        }
        dispatch(accessTokenInfoRequest(data))
    }

    let getTenantInfo = () => {
        setTokenLoading(true)
        const callback = (data: any) => {
            if (data?.status) {
                // Cookies.set('babtbu', data?.data?.token, { secure: true })
                // Cookies.set("org", data?.data?.organisation[0]?.organisation?.id, { secure: true })

                Cookies.set('btbibd', data?.tenant?.id, { secure: true })
                setTokenLoading(false)
                // window.location.href = window.location.pathname
            } else {
                setTokenLoading(false)
                setNotifTitle("Error")
                setNotif(data?.detail)
                setNotifVal(true)
                // window.location.href = global.appBaseUrl + "login"
            }
        }
        let data = {
            values: {
                host: window.location.hostname?.includes('localhost') ? "dev.prembly.com" : window.location.hostname,
                // host: "account.prembly.com",
            },
            callback,
        }
        dispatch(tenantInfoRequest(data))
    }

    if (tokenLoading === true) {
        return (
            <div>
                {notif && notifVal && (
                    <NotificationToast
                        title={notifTitle}
                        message={notif}
                        closeNotif={() => setNotifVal(!notifVal)}
                    />
                )}
                <LoadingPage />
            </div>
        )
    }
    else {
        return (
            <div>
                {props.children}
            </div>
        )

    }
}
