import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { loginSuccess, loginFailure, confirmLogin2FASuccess, confirmLogin2FAFailure } from "../../../actions/auth/login";
import {actionTypes} from "../../../constants/actionTypes"
import { IAuth } from "../../../actions/auth/login/types";
import global from "../../../constants/global";
import { serverCodes } from "../../../constants/api";

const login = async (payload: any) => {
  const { data } = await axios.post<IAuth[]>(
    global.apiBaseUrl + global.liveUrl + "api/v1/auth/login",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return data;
};
const confirmLogin2FA = async (payload: any) => {
  const { data } = await axios.post<IAuth[]>(
    global.apiBaseUrl + global.liveUrl + "api/v1/auth/login/two_fa",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return data;
};

function* loginSaga(action: any) {
  try {
    const response: { data: any } = yield call(login, {
      email: action.payload.values.email,
      password: action.payload.values.password,
      ip: action.payload.values.ip,
      host: action.payload.values.host
    });
    yield put(
      loginSuccess({
        tokens: response?.data,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        loginFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    yield put(
      loginFailure({
        error: e.response?.data?.detail,
      })
    );
    action.payload.callback(e.response?.data);
  }
}
function* confirmLogin2FASaga(action: any) {
  try {
    const response: { data: any } = yield call(confirmLogin2FA, {
      email: action.payload.values.email,
      otp: action.payload.values.otp,
      session_id: action.payload.values.session_id,
    });
    yield put(
      confirmLogin2FASuccess({
        resp: response?.data,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        confirmLogin2FAFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    yield put(
      confirmLogin2FAFailure({
        error: e.response?.data?.detail,
      })
    );
    action.payload.callback(e.response?.data);
  }
}


export function* loginSagaTrigger() {
  yield all([takeLatest(actionTypes.LOGIN_REQUEST, loginSaga)]);
}
export function* confirmLogin2FASagaTrigger() {
  yield all([takeLatest(actionTypes.CONFIRM_LOGIN_2FA_REQUEST, confirmLogin2FASaga)]);
}