import React from "react";



type RadioBtnWithLabel ={
    type:'radio'|'checkbox',
    defaultChecked?:boolean,
    label:any,
    // name:string,
    style?:React.CSSProperties,
    value:string,
    onChange:(data:{value:string,isChecked?:boolean})=>void,
    id:any
}

const RadioBtnWithLabel = ({ type='radio',defaultChecked=false,label,style={},onChange,value,id}:RadioBtnWithLabel)=>{

    return (

   <div>
     <label 
    // htmlFor={`${value.id}`}
    htmlFor={id}

className="radio_container" style={{
'paddingLeft':'25px',
display:'flex',
alignItems:'center',
margin:'unset',

}}>
  <input
    type={type}
    defaultChecked={defaultChecked}
    id={id}
    // name={name}
    className="hidden"
    onClick={e=>{
        if(onChange){
            onChange({
               // @ts-ignore
                'isChecked': e.target.checked,
                value:value
            })
        }
    }}
    // value={value}
  />
  
  <span className="radio_checkmark__sm" ></span>
  <p
    // className="text-[#1D2433] font-[500] text-[1rem] !m-[0] !p-[0] flex items-center gap-[0.25rem]"
    style={{
        gap:'0.25rem',
        fontFamily:'--general-sans-regular',fontWeight:'400',
fontSize:'.9rem',
        ...style,
        
    }}
  >
    <span>{label}</span>
  </p>
</label>

   </div>
)
    
};

export default RadioBtnWithLabel;