import React from 'react'
import { Template } from '../../data'

interface TemplateCardProps {
    template: Template
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template }:any) => {
    const firstLetter = template.name.charAt(0)

    return (
        <div className="card mb-4 rounded-16">
            <div className="card-body">
                <div
                    style={{
                        backgroundColor: '#DAF1EE',
                        color: '#0B0A0A',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        marginRight: '10px',
                        lineHeight: '32px',
                        fontWeight: '700',
                    }}>
                    {firstLetter}
                </div>
                <h3 className="template-text mt-4">{template.name}</h3>

                <p className=" sub-text ">{template.description}</p>
                <p className="temp-text">
                    {template.type}
                    <span className="dot"></span>
                    {template.country}
                    <span className="dot"></span>
                    {template.endpoint} Endpoints
                </p>

                <button className="btn btn-grey">Use Template</button>
            </div>
        </div>
    )
}

export default TemplateCard
