import { actionTypes } from '../../../constants/actionTypes'
import { 
    LoginPayload, LoginRequest, LoginSuccess, 
    LoginSuccessPayload, LoginFailure, LoginFailurePayload, 
    
    ConfirmLogin2FAPayload, ConfirmLogin2FARequest, ConfirmLogin2FASuccessPayload,
    ConfirmLogin2FASuccess, ConfirmLogin2FAFailurePayload, ConfirmLogin2FAFailure
} from "./types";


export const loginRequest = (payload: LoginPayload): LoginRequest => ({
    type: actionTypes.LOGIN_REQUEST,
    payload,
});

export const loginSuccess = (payload: LoginSuccessPayload): LoginSuccess => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload,
});

export const loginFailure = (payload: LoginFailurePayload): LoginFailure => ({
    type: actionTypes.LOGIN_FAILURE,
    payload,
});




export const confirmLogin2FARequest = (payload:ConfirmLogin2FAPayload):ConfirmLogin2FARequest => ({
    type: actionTypes.CONFIRM_LOGIN_2FA_REQUEST,
    payload,
});

export const confirmLogin2FASuccess = (payload:ConfirmLogin2FASuccessPayload):ConfirmLogin2FASuccess => ({
    type: actionTypes.CONFIRM_LOGIN_2FA_SUCCESS,
    payload,
});

export const confirmLogin2FAFailure = (payload:ConfirmLogin2FAFailurePayload):ConfirmLogin2FAFailure => ({
    type: actionTypes.CONFIRM_LOGIN_2FA_FAILURE,
    payload,
});

