import React from 'react'
import SDKConfigComp from '../../../components/sdkLibraries/webhook/config'
import SDKConfigPreviewComp from '../../../components/sdkLibraries/webhook/config/preview'

export default function SDKConfigPage() {
    return (
        <div className='container-fluid px-md-4'>
            <SDKConfigComp/>
        </div>
    )
}
