import './styles/styles.css'
import star from '../../../src/assets/star.png'
import send from '../../../src/assets/send.png'
import edit from '../../../src/assets/edit-2.png'
import del from '../../../src/assets/trash.png'
import copy from '../../../src/assets/document-copy.png'
import more from '../../../src/assets/more.png'
import noApi from '../../../src/assets/noApi.png'
import goBack from '../../../src/assets/GoBack.png'
import eye from '../../../src/assets/eye.png'
import { DeleteModal } from './DeleteModal'
import { Link } from 'react-router-dom'
import TemplateCard from './TemplateCard'
import { templateCollection, templates as defaultTemplates } from '../../data'
import { RecentAPIs } from '../../data'
import { useState, useEffect } from 'react'
import { EditModal } from './EditModal'
// import { toast, Toaster } from 'react-hot-toast'
// import { FaLink } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import NotificationToast from '../utils/notifToast'
import toast from 'react-hot-toast'

const formatDate = (date: string): string => {
    return date
}

export default function ApiBuilder({ templates: initialTemplates = defaultTemplates }) {
    const navigate = useNavigate()

    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')

    const [isOpenMenu, setIsOpenMenu] = useState<number | null>(null)
    const [selectedApiIndex, setSelectedApiIndex] = useState<number | null>(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
    const [editApiDetails, setEditApiDetails] = useState<RecentAPIs | null>(null)
    const [templates, setTemplates] = useState<RecentAPIs[]>(initialTemplates)

    const [userInput, setUserInput] = useState<string>('')

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value)
    }

    const handleInputSubmit = () => {
        if (userInput.trim() !== '') {
            navigate('/aichat', { state: { userInput } })
        }
    }

    const handleMenuClick = (index: any) => {
        setIsOpenMenu((prevIndex) => (prevIndex === index ? null : index))
    }

    const handleDeleteClick = (index: number) => {
        setSelectedApiIndex(index)
        setIsDeleteModalOpen(true)
    }

    const handleDeleteConfirm = () => {
        if (selectedApiIndex !== null) {
            const updatedTemplates = templates.filter((_, index) => index !== selectedApiIndex)
            setTemplates(updatedTemplates)
            setIsDeleteModalOpen(false)
            // toast.success('API template deleted successfully', {
            //     duration: 6000,
            //     position: 'top-right',
            // })
        }
    }

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false)
    }

    const handleEditClick = (index: number) => {
        setEditApiDetails(templates[index])
        setSelectedApiIndex(index)
        setIsEditModalOpen(true)
    }

    const DrafthandleEditClick = (index: number) => {
        const apiDetails = templates[index]
        console.log(apiDetails, 'defdxdgdgh')

        navigate('/createapi', { state: { apiDetails } }) // Navigate to Create API page with state
    }

    const handleSaveChanges = (updatedApiDetails: RecentAPIs) => {
        if (selectedApiIndex !== null) {
            const updatedTemplates = templates.map((api, index) =>
                index === selectedApiIndex ? updatedApiDetails : api
            )
            setTemplates(updatedTemplates)
            setIsEditModalOpen(false)
            setNotifTitle('Success')
            setNotif('API template edited successfully')
            setNotifVal(true)
        }
    }

    const handleDuplicateClick = (index: number) => {
        const apiToDuplicate = templates[index]
        const duplicatedApi = { ...apiToDuplicate, name: `${apiToDuplicate.name} (Copy)` }
        setTemplates([...templates, duplicatedApi])

        setNotifTitle('Success')
        setNotif('API template duplicated successfully')
        setNotifVal(true)
    }

    // const handleViewClick = (index: number) => {
    //     const apiDetails = templates[index]
    //     navigate('/viewapi', { state: apiDetails })
    // }

    // const handleCopyLink = (index: number) => {
    //     const itemLink = `${window.location.origin}/viewapi/${index}`
    //     navigator.clipboard.writeText(itemLink)

    //     setNotifTitle('Success')
    //     setNotif('Link copied successfully')
    //     setNotifVal(true)
    // }

    const handleViewClick = (index: number) => {
        const apiDetails = templates[index]
        navigate('/viewapi', { state: apiDetails })
    }

    const handleCopyLink = (index: number) => {
        const itemLink = `${window.location.origin}/viewapi/${index}`
        navigator.clipboard.writeText(itemLink)
        toast.success('Link copied successfully', {
            duration: 3000,
            position: 'top-right',
        })
    }

    return (
        <div className="dashboard-area vh-100 mb-4">
            {notif && notifVal && (
                <NotificationToast
                    title={notifTitle}
                    message={notif}
                    closeNotif={() => setNotifVal(!notifVal)}
                />
            )}
            <div className="container-fluid h-100 px-md-4 my-4">
                <div className="row h-100">
                    <div className="col-md-8 d-flex flex-column">
                        {/* API Builder */}
                        <div className="card mb-4 cont">
                            <div className="card-body">
                                <a
                                    href="/createapi"
                                    style={{
                                        lineHeight: '20px',
                                        textDecoration: 'none',
                                        background: '#F4F4F4',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        color: '#1D1D1D',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        width: '156px',
                                    }}>
                                    <span
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            display: 'inline-block',
                                            marginRight: '8px',
                                        }}>
                                        <img src={star} />
                                    </span>
                                    BUILD WITH AI
                                </a>
                                <h2 className="header-style mt-3">
                                    Use our best in class AI engine to create helpful APIs with a
                                    few prompts to help speed up your process
                                </h2>

                                <div className="mt-3">
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <input
                                            type="text"
                                            placeholder="Enter your prompt"
                                            value={userInput}
                                            onChange={handleInputChange}
                                            onKeyDown={(e) =>
                                                e.key === 'Enter' && handleInputSubmit()
                                            }
                                            style={{
                                                width: '100%',
                                                padding: '10px 40px 10px 10px',
                                                borderRadius: '10px',
                                                border: '1px solid #ccc',
                                            }}
                                        />
                                        <span
                                            onClick={handleInputSubmit}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                width: '24px',
                                                height: '24px',
                                                display: 'inline-block',
                                                textAlign: 'center',
                                                lineHeight: '20px',
                                                cursor: 'pointer',
                                            }}>
                                            <img src={send} />
                                        </span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: '8px',
                                        borderRadius: '16px',
                                    }}>
                                    <div
                                        style={{
                                            background: '#DAF1EE',
                                            padding: '16px',
                                            borderRadius: '8px',
                                            display: 'flex',
                                        }}>
                                        <span
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                display: 'inline-flex',
                                                border: '3px solid currentColor',
                                                borderRadius: '50%',
                                                textAlign: 'center',
                                                lineHeight: '20px',
                                                fontSize: '16px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                            !
                                        </span>
                                    </div>
                                    <p className="sub-text mt-3" style={{ marginLeft: '8px' }}>
                                        eg: Create an API that bills Rotimi anytime I breathe.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* API Template */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <h5 className="card-title">API Templates</h5>

                            <Link className="view-all-link" to="/generalapi">
                                View All
                            </Link>
                        </div>
                        <div className="row mt-4 temp">
                            {templateCollection.slice(0, 3).map(
                                (
                                    template,
                                    index // Slicing to display only the first 3 templates
                                ) => (
                                    <div
                                        key={index}
                                        className="col-md-4"
                                        style={{ padding: '7px' }}>
                                            {/* @ts-ignore */}
                                        <TemplateCard template={template} index={index} />
                                    </div>
                                )
                            )}
                        </div>

                        {/* Recent APIs */}

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <h5 className="card-title">Recent APIs</h5>

                            <div>
                                <img src={goBack} alt="goback" />
                                <Link className="view-all-link" to="/apiTemplate">
                                    View All
                                </Link>
                            </div>
                        </div>
                        {templates && templates.length === 0 ? (
                            <div className="no-api-container">
                                <img src={noApi} alt="No API" />
                                <div className="no-api-text">
                                    <h3 className="template-text mt-4">No APIs Created</h3>
                                    <p className="sub-text">
                                        You currently have not created an API. Create an API to view
                                        them here.
                                    </p>
                                    <button className="btn btn-light-green w-100 py-3 create-api mb-4">
                                        Create New API
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Name of Endpoint</th>
                                        <th scope="col">Created</th>
                                        <th scope="col">Created By</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates?.map((item, index) => (
                                        <tr key={index} className="api-item">
                                            <td>
                                                <div className="cell-content">
                                                    <span
                                                        className={`api-dot ${
                                                            item.status === 'PUBLISHED'
                                                                ? 'published'
                                                                : 'DRAFTS'
                                                        }`}></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-content endpoint-name">
                                                    <span className="endpoint-first-letter">
                                                        {item.name.charAt(0).toUpperCase()}
                                                    </span>
                                                    {item.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-content">
                                                    {formatDate(item.date)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-content">{item.createdBy}</div>
                                            </td>
                                            <td>
                                                <div className="cell-content">
                                                    <span
                                                        className={`status ${
                                                            item.status === 'PUBLISHED'
                                                                ? 'published'
                                                                : 'DRAFTS'
                                                        }`}>
                                                        {item.status}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="menu-container">
                                                    <button
                                                        className="menu-icon"
                                                        onClick={() => handleMenuClick(index)}>
                                                        <img src={more} alt="More" />
                                                    </button>
                                                    {isOpenMenu === index && (
                                                        <ul className="menu-options">
                                                            {item.status === 'PUBLISHED' ? (
                                                                <>
                                                                    <li
                                                                        onClick={() =>
                                                                            handleEditClick(index)
                                                                        }>
                                                                        <img
                                                                            src={edit}
                                                                            alt="Edit"
                                                                            className="menu-icon"
                                                                        />
                                                                        Rename
                                                                    </li>
                                                                    <li
                                                                        onClick={() =>
                                                                            handleViewClick(index)
                                                                        }>
                                                                        <img
                                                                            src={eye}
                                                                            alt="View"
                                                                            className="menu-icon"
                                                                        />
                                                                        View
                                                                    </li>
                                                                    <li
                                                                        onClick={() =>
                                                                            handleDuplicateClick(
                                                                                index
                                                                            )
                                                                        }>
                                                                        <img
                                                                            src={copy}
                                                                            alt="Duplicate"
                                                                            className="menu-icon"
                                                                        />
                                                                        Duplicate API
                                                                    </li>
                                                                    <li
                                                                        onClick={() => {
                                                                            handleCopyLink(index)
                                                                        }}>
                                                                        {/* <FaLink className="menu-icon" /> */}
                                                                        Copy Link
                                                                    </li>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <li
                                                                        onClick={() =>
                                                                            DrafthandleEditClick(
                                                                                index
                                                                            )
                                                                        }>
                                                                        <img
                                                                            src={edit}
                                                                            alt="Edit"
                                                                            className="menu-icon"
                                                                        />
                                                                        Edit API
                                                                    </li>
                                                                    <li
                                                                        onClick={() =>
                                                                            handleDuplicateClick(
                                                                                index
                                                                            )
                                                                        }>
                                                                        <img
                                                                            src={copy}
                                                                            alt="Duplicate"
                                                                            className="menu-icon"
                                                                        />
                                                                        Duplicate API
                                                                    </li>
                                                                    <li
                                                                        onClick={() =>
                                                                            handleDeleteClick(index)
                                                                        }>
                                                                        <img
                                                                            src={del}
                                                                            alt="Delete"
                                                                            className="menu-icon"
                                                                        />
                                                                        Delete API
                                                                    </li>
                                                                </>
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {/* Modal for delete confirmation */}
                        <DeleteModal
                            isOpen={isDeleteModalOpen}
                            onClose={handleDeleteCancel}
                            onDelete={handleDeleteConfirm}
                        />
                        {/* Modal for edit API */}
                        <EditModal
                            isOpen={isEditModalOpen}
                            apiDetails={editApiDetails}
                            onClose={() => setIsEditModalOpen(false)}
                            onSave={handleSaveChanges}
                        />
                    </div>

                    {/* Right Section */}
                    <div className="col-md-4 mb-5">
                        <div
                            className="card w-100"
                            style={{
                                borderRadius: '10px',
                                minHeight: '200px',
                            }}>
                            <div className="card-body">
                                <a
                                    href="/createapi"
                                    style={{
                                        lineHeight: '20px',
                                        textDecoration: 'none',
                                        background: '#F4F4F4',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        color: '#1D1D1D',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}>
                                    <span
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            display: 'inline-block',
                                            marginRight: '8px',
                                            border: '2px solid currentColor',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            lineHeight: '20px',
                                            fontSize: '16px',
                                        }}>
                                        +
                                    </span>
                                    CREATE MANUALLY
                                </a>

                                <h2 className="header-style mt-4">
                                    {' '}
                                    Use Prembly infrastructure to build the APIs you need
                                </h2>
                                <p className="sub-text">
                                    We have made it easy for you to create API’s to help your
                                    business flows
                                </p>

                                <Link
                                    to="/createapi"
                                    className="btn btn-light-green w-100 py-3 create-api">
                                    Create New API
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
