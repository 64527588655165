import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../../constants/actionTypes"
import global from "../../constants/global";
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import { ISdkBiometricsInfo, ISdkCreate, ISdkDeleteSingle, ISdkEndpointsInfo, ISdkInfoAll, ISdkInfoSingle, ISdkReportInfo, ISdkReportSingleInfo, ISdkUpdate, IUpdateWebhookUrl } from "../../actions/sdkLibraries/types";
import { sdkBiometricsInfoFailure, sdkBiometricsInfoSuccess, sdkCreateFailure, sdkCreateSuccess, sdkDeleteSingleFailure, sdkDeleteSingleSuccess, sdkEndpointsInfoFailure, sdkEndpointsInfoSuccess, sdkInfoAllFailure, sdkInfoAllSuccess, sdkInfoSingleFailure, sdkInfoSingleSuccess, sdkReportInfoFailure, sdkReportInfoSuccess, sdkReportSingleInfoFailure, sdkReportSingleInfoSuccess, sdkUpdateFailure, sdkUpdateSuccess, updateWebhookUrlFailure, updateWebhookUrlSuccess } from "../../actions/sdkLibraries";

let accessT = Cookies.get("babtbu") || ""
let orgId = Cookies.get("org") || ""

const updateWebhookUrl = async (payload: any) => {
  const { data } = await axios.put<IUpdateWebhookUrl[]>(
    global.apiBaseUrl + global.liveUrl + `api/v1/account/organisation/${orgId}/update`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkEndpointsInfo = async (payload: any) => {
  const { data } = await axios.get<ISdkEndpointsInfo[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/endpoint/get",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkCreate = async (payload: any) => {
  const { data } = await axios.post<ISdkCreate[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/config/create",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkInfoAll = async (payload: any) => {
  const { data } = await axios.get<ISdkInfoAll[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/config/get",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkUpdate = async (payload: any) => {
  const { data } = await axios.post<ISdkUpdate[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/config/update",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkInfoSingle = async (payload: any) => {
  const { data } = await axios.post<ISdkInfoSingle[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/config/single/get",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkBiometricsInfo = async (payload: any) => {
  const { data } = await axios.get<ISdkBiometricsInfo[]>(
    global.apiBaseUrl + "",
    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "123",
        "prembly-product": "123"
      },
    }
  );
  return data;
};
const sdkReportInfo = async (payload: any) => {
  const { data } = await axios.get<ISdkReportInfo[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/sdk/report/list",
    // 'http://sp-staging-gateway.prembly.com/internal/api/sdk-report/list',
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkReportSingleInfo = async (payload: any) => {
  const { data } = await axios.post<ISdkReportSingleInfo[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/sdk/report/get",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};
const sdkDeleteSingle = async (payload: any) => {
  const { data } = await axios.post<ISdkDeleteSingle[]>(
    global.apiBaseUrl + global.liveUrl + "checker/v1/config/delete",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Organisation: orgId,
      },
    }
  );
  return data;
};


function* updateWebhookUrlSaga(action: any) {
  try {
    const response: { data: any } = yield call(updateWebhookUrl, {
      webhook_url: action.payload.values.webhook_url,
    });

    yield put(
      updateWebhookUrlSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        updateWebhookUrlFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        updateWebhookUrlFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkEndpointsInfoSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkEndpointsInfo, {});

    yield put(
      sdkEndpointsInfoSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkEndpointsInfoFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkEndpointsInfoFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkCreateSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkCreate, {
      name: action.payload.values.name, 
      widget_mode:action.payload.values.widget_mode,
      product: action.payload.values.product
    });

    yield put(
      sdkCreateSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkCreateFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkCreateFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkInfoAllSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkInfoAll, { });

    yield put(
      sdkInfoAllSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkInfoAllFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkInfoAllFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkUpdateSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkUpdate, {
      webhook_url: action.payload.values.webhook_url,
      config_id: action.payload.values.config_id,
      name: action.payload.values.name,
      subtitle: action.payload.values.subtitle,
      countries: action.payload.values.countries,
      endpoints: action.payload.values.endpoints,
      radar_endpoints: action.payload.values.radar_endpoints,
      dynamism: action.payload.values.dynamism,
      face_confidence: action.payload.values.face_confidence,
      is_active: action.payload.values.is_active,
      theme_color: action.payload.values.theme_color,
      biometric_type:'dynamism'
    });

    yield put(
      sdkUpdateSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkUpdateFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkUpdateFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkInfoSingleSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkInfoSingle, {
      webhook_url: action.payload.values.webhook_url,
      config_id: action.payload.values.config_id
    });

    yield put(
      sdkInfoSingleSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkInfoSingleFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkInfoSingleFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkBiometricsInfoSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkBiometricsInfo, { });

    yield put(
      sdkBiometricsInfoSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkBiometricsInfoFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkBiometricsInfoFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkReportInfoSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkReportInfo, { });

    yield put(
      sdkReportInfoSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkReportInfoFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkReportInfoFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkDeleteSingleSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkDeleteSingle, {
      webhook_url: action.payload.values.webhook_url,
      config_id: action.payload.values.config_id
    });

    yield put(
      sdkDeleteSingleSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkDeleteSingleFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkDeleteSingleFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}
function* sdkReportSingleInfoSaga(action: any) {
  try {
    const response: { data: any } = yield call(sdkReportSingleInfo, {
      reference: action.payload.values.reference,
    });

    yield put(
      sdkReportSingleInfoSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        sdkReportSingleInfoFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        sdkReportSingleInfoFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}



export function* updateWebhookUrlSagaTrigger() {
  yield all([takeLatest(actionTypes.UPDATE_WEBHOOK_URL_REQUEST, updateWebhookUrlSaga)]);
}
export function* sdkEndpointsInfoSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_ENDPOINTS_INFO_REQUEST, sdkEndpointsInfoSaga)]);
}
export function* sdkCreateSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_CREATE_REQUEST, sdkCreateSaga)]);
}
export function* sdkInfoAllSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_INFO_ALL_REQUEST, sdkInfoAllSaga)]);
}
export function* sdkUpdateSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_UPDATE_REQUEST, sdkUpdateSaga)]);
}
export function* sdkInfoSingleSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_INFO_SINGLE_REQUEST, sdkInfoSingleSaga)]);
}
export function* sdkBiometricsInfoSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_BIOMETRICS_INFO_REQUEST, sdkBiometricsInfoSaga)]);
}
export function* sdkReportInfoSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_REPORT_INFO_REQUEST, sdkReportInfoSaga)]);
}
export function* sdkReportSingleInfoSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_REPORT_SINGLE_INFO_REQUEST, sdkReportSingleInfoSaga)]);
}
export function* sdkDeleteSingleSagaTrigger() {
  yield all([takeLatest(actionTypes.SDK_DELETE_SINGLE_REQUEST, sdkDeleteSingleSaga)]);
}