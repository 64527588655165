import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Mainloader, { EmptyWidgetStateComp, FailedTag, SuccessTag } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers'
import { sdkReportInfoRequest } from '../../../redux/actions/sdkLibraries'
import NotificationToast from '../../utils/notifToast'
import { Link } from 'react-router-dom'

export default function SDKReportListComp() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")


    const sdkReportState = useSelector((state: RootState) => state.sdkReportInfoReducer);


    const dispatch = useDispatch()


    useEffect(() => {
        getSDKReport()
    }, [])

    let getSDKReport = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {},
            callback,
        };
        dispatch(sdkReportInfoRequest(data))
    }

    return (
        <div className='px-4 sdk-table-area'>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div>
                <h4>Reports</h4>
            </div>

            {sdkReportState?.isLoading && <Mainloader />}


            {!sdkReportState?.isLoading &&
                <>
                    <div className="table-responsive mt-3">
                        {sdkReportState?.resp?.data?.length > 0 && (
                            <table className=" sdk-table w-100">
                                <thead className="w-100">
                                    <tr>
                                        {/* <th scope="col">Name</th> */}
                                        <th className='' scope="col">ENDPOINT</th>
                                        <th className='' scope="col">REF</th>
                                        <th className='' scope="col">SOURCE</th>
                                        <th className='' scope="col">DATE</th>
                                        <th className='' scope="col">STATUS</th>
                                        <th className='' scope="col">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sdkReportState?.resp?.data?.map((val: any, i: number) => (
                                        <tr key={i} className='rounded- w-100 shadow-sm'>
                                            {/* <th scope="row"> {val?.name} </th> */}
                                            <td className=''>{val?.widget_details?.endpoint_code}</td>
                                            <td className=''>{val?.reference} </td>
                                            <td className=''> {val?.widget_details?.widget_title}</td>
                                            <td className=''> {moment(val?.widget_details?.report_created).format('LL')}</td>
                                            <td className=''>
                                                {val?.widget_details?.data_request_status === "00" ?
                                                    <SuccessTag />
                                                    :
                                                    <FailedTag />
                                                }
                                            </td>
                                            <td className=''>
                                                <Link to={`/SDK-Library/SDK-Report/${val?.reference}`} className='link'>
                                                    <button
                                                        className="btn btn-view"
                                                    >
                                                        <i className="ri-eye-line me-3 ri-xl" />
                                                        View
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}

                                    {/* {sdkReportState?.resp?.data?.map(
                                    (val: any, i: number) => (
                                        <tr key={i}>
                                            <td>
                                                {' '}
                                                {val?.verification_status ===
                                                    'VERIFIED'
                                                    ? val?.price
                                                    : 'Not Charged'}{' '}
                                            </td>
                                            <td>
                                                {val?.performed_by ||
                                                    'Name Not Available'}
                                            </td>
                                            <td>
                                                {' '}
                                                {moment
                                                    .utc(val?.created_at)
                                                    .format('lll')}
                                            </td>
                                        </tr>
                                    )
                                )} */}
                                </tbody>
                            </table>
                        )}


                        {/* <div className="d-flex justify-content-end align-items-center">
                        {reportData?.previous && (
                            <p
                                style={{ cursor: 'pointer' }}
                                className="mb-0 me-3"
                                onClick={() =>
                                    triggerPage(reportData?.previous)
                                }>
                                Prev
                            </p>
                        )}
                        <button className="btn btn-green">{pageNumber}</button>
                        {reportData?.next && (
                            <p
                                style={{ cursor: 'pointer' }}
                                className="mb-0 ms-3"
                                onClick={() => triggerPage(reportData?.next)}>
                                Next
                            </p>
                        )}
                    </div> */}
                    </div>

                    {sdkReportState?.resp?.data?.length < 1 && (
                        <div className="col-md-4 mx-auto mt-5">
                            <EmptyWidgetStateComp
                                title={"No Record Found"}
                                ctaAction={() => { }}
                                desc={"You are yet to get a report because there is no record of any check"}
                                ctaValue={""}
                            />
                        </div>
                    )}
                </>
            }
        </div>
    )
}
