

const Announcement = ()=>{




    return(
        <div style={{
            backgroundColor:'#37b7ab',
            color:'white',
            padding:'.8rem',
            textAlign:'center'
        }}>
             
            <p>We've just updated our Terms & Conditions to ensure that we continue providing you with top-tier, compliant data services. <a
             href="https://prembly.com/terms"
             target='_blank'
            style={{color:'white',}}
            >Learn more.</a></p>
        </div>
    )
 }
 
 
 export default Announcement;