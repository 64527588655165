import { Form,  } from 'react-bootstrap'


type Prop ={
label:string,
register:any,
errorMessage?:string,
}
const InputWithLabel = ({label,register,errorMessage}:Prop)=>{


    return (
        <Form.Group controlId={label} className="">
        <Form.Label>{label} </Form.Label>
        <Form.Control
            type="text"
            // placeholder="Enter Endpoint Name"
            // required
            // value={formikStep1.values.endpointname}
            // onChange={formikStep1.handleChange}
            // onBlur={formikStep1.handleBlur}
            {...register}
        />
        {
        errorMessage ? 
            <div className="error">
                {errorMessage}
            </div>
        : null}
    </Form.Group>
    )
}

export default InputWithLabel;