export const countryList = [
    {
      dialCode: "+93",
      code: "AF"
    },
    {
      dialCode: "+355",
      code: "AL"
    },
    {
      dialCode: "+213",
      code: "DZ"
    },
    {
      dialCode: "+1 684",
      code: "AS"
    },
    {
      dialCode: "+376",
      code: "AD"
    },
    {
      dialCode: "+244",
      code: "AO"
    },
    {
      dialCode: "+1 264",
      code: "AI"
    },
    {
      dialCode: "+672",
      code: "AQ"
    },
    {
      dialCode: "+1268",
      code: "AG"
    },
    {
      dialCode: "+54",
      code: "AR"
    },
    {
      dialCode: "+374",
      code: "AM"
    },
    {
      dialCode: "+297",
      code: "AW"
    },
    {
      dialCode: "+61",
      code: "AU"
    },
    {
      dialCode: "+43",
      code: "AT"
    },
    {
      dialCode: "+994",
      code: "AZ"
    },
    {
      dialCode: "+1 242",
      code: "BS"
    },
    {
      dialCode: "+973",
      code: "BH"
    },
    {
      dialCode: "+880",
      code: "BD"
    },
    {
      dialCode: "+1 246",
      code: "BB"
    },
    {
      dialCode: "+375",
      code: "BY"
    },
    {
      dialCode: "+32",
      code: "BE"
    },
    {
      dialCode: "+501",
      code: "BZ"
    },
    {
      dialCode: "+229",
      code: "BJ"
    },
    {
      dialCode: "+1 441",
      code: "BM"
    },
    {
      dialCode: "+975",
      code: "BT"
    },
    {
      dialCode: "+591",
      code: "BO"
    },
    {
      dialCode: "+387",
      code: "BA"
    },
    {
      dialCode: "+267",
      code: "BW"
    },
    {
      dialCode: "+55",
      code: "BR"
    },
    {
      dialCode: "+246",
      code: "IO"
    },
    {
      dialCode: "+673",
      code: "BN"
    },
    {
      dialCode: "+359",
      code: "BG"
    },
    {
      dialCode: "+226",
      code: "BF"
    },
    {
      dialCode: "+257",
      code: "BI"
    },
    {
      dialCode: "+855",
      code: "KH"
    },
    {
      dialCode: "+237",
      code: "CM"
    },
    {
      dialCode: "+1",
      code: "CA"
    },
    {
      dialCode: "+238",
      code: "CV"
    },
    {
      dialCode: "+ 345",
      code: "KY"
    },
    {
      dialCode: "+236",
      code: "CF"
    },
    {
      dialCode: "+235",
      code: "TD"
    },
    {
      dialCode: "+56",
      code: "CL"
    },
    {
      dialCode: "+86",
      code: "CN"
    },
    {
      dialCode: "+61",
      code: "CX"
    },
    {
      dialCode: "+61",
      code: "CC"
    },
    {
      dialCode: "+57",
      code: "CO"
    },
    {
      dialCode: "+269",
      code: "KM"
    },
    {
      dialCode: "+242",
      code: "CG"
    },
    {
      dialCode: "+243",
      code: "CD"
    },
    {
      dialCode: "+682",
      code: "CK"
    },
    {
      dialCode: "+506",
      code: "CR"
    },
    {
      dialCode: "+225",
      code: "CI"
    },
    {
      dialCode: "+385",
      code: "HR"
    },
    {
      dialCode: "+53",
      code: "CU"
    },
    {
      dialCode: "+357",
      code: "CY"
    },
    {
      dialCode: "+420",
      code: "CZ"
    },
    {
      dialCode: "+45",
      code: "DK"
    },
    {
      dialCode: "+253",
      code: "DJ"
    },
    {
      dialCode: "+1 767",
      code: "DM"
    },
    {
      dialCode: "+1 849",
      code: "DO"
    },
    {
      dialCode: "+593",
      code: "EC"
    },
    {
      dialCode: "+20",
      code: "EG"
    },
    {
      dialCode: "+503",
      code: "SV"
    },
    {
      dialCode: "+240",
      code: "GQ"
    },
    {
      dialCode: "+291",
      code: "ER"
    },
    {
      dialCode: "+372",
      code: "EE"
    },
    {
      dialCode: "+251",
      code: "ET"
    },
    {
      dialCode: "+500",
      code: "FK"
    },
    {
      dialCode: "+298",
      code: "FO"
    },
    {
      dialCode: "+679",
      code: "FJ"
    },
    {
      dialCode: "+358",
      code: "FI"
    },
    {
      dialCode: "+33",
      code: "FR"
    },
    {
      dialCode: "+594",
      code: "GF"
    },
    {
      dialCode: "+689",
      code: "PF"
    },
    {
      dialCode: "+241",
      code: "GA"
    },
    {
      dialCode: "+220",
      code: "GM"
    },
    {
      dialCode: "+995",
      code: "GE"
    },
    {
      dialCode: "+49",
      code: "DE"
    },
    {
      dialCode: "+233",
      code: "GH"
    },
    {
      dialCode: "+350",
      code: "GI"
    },
    {
      dialCode: "+30",
      code: "GR"
    },
    {
      dialCode: "+299",
      code: "GL"
    },
    {
      dialCode: "+1 473",
      code: "GD"
    },
    {
      dialCode: "+590",
      code: "GP"
    },
    {
      dialCode: "+1 671",
      code: "GU"
    },
    {
      dialCode: "+502",
      code: "GT"
    },
    {
      dialCode: "+44",
      code: "GG"
    },
    {
      dialCode: "+224",
      code: "GN"
    },
    {
      dialCode: "+245",
      code: "GW"
    },
    {
      dialCode: "+595",
      code: "GY"
    },
    {
      dialCode: "+509",
      code: "HT"
    },
    {
      dialCode: "+379",
      code: "VA"
    },
    {
      dialCode: "+504",
      code: "HN"
    },
    {
      dialCode: "+852",
      code: "HK"
    },
    {
      dialCode: "+36",
      code: "HU"
    },
    {
      dialCode: "+354",
      code: "IS"
    },
    {
      dialCode: "+91",
      code: "IN"
    },
    {
      dialCode: "+62",
      code: "ID"
    },
    {
      dialCode: "+98",
      code: "IR"
    },
    {
      dialCode: "+964",
      code: "IQ"
    },
    {
      dialCode: "+353",
      code: "IE"
    },
    {
      dialCode: "+44",
      code: "IM"
    },
    {
      dialCode: "+972",
      code: "IL"
    },
    {
      dialCode: "+39",
      code: "IT"
    },
    {
      dialCode: "+1 876",
      code: "JM"
    },
    {
      dialCode: "+81",
      code: "JP"
    },
    {
      dialCode: "+44",
      code: "JE"
    },
    {
      dialCode: "+962",
      code: "JO"
    },
    {
      dialCode: "+7 7",
      code: "KZ"
    },
    {
      dialCode: "+254",
      code: "KE"
    },
    {
      dialCode: "+686",
      code: "KI"
    },
    {
      dialCode: "+850",
      code: "KP"
    },
    {
      dialCode: "+82",
      code: "KR"
    },
    {
      dialCode: "+965",
      code: "KW"
    },
    {
      dialCode: "+996",
      code: "KG"
    },
    {
      dialCode: "+856",
      code: "LA"
    },
    {
      dialCode: "+371",
      code: "LV"
    },
    {
      dialCode: "+961",
      code: "LB"
    },
    {
      dialCode: "+266",
      code: "LS"
    },
    {
      dialCode: "+231",
      code: "LR"
    },
    {
      dialCode: "+218",
      code: "LY"
    },
    {
      dialCode: "+423",
      code: "LI"
    },
    {
      dialCode: "+370",
      code: "LT"
    },
    {
      dialCode: "+352",
      code: "LU"
    },
    {
      dialCode: "+853",
      code: "MO"
    },
    {
      dialCode: "+389",
      code: "MK"
    },
    {
      dialCode: "+261",
      code: "MG"
    },
    {
      dialCode: "+265",
      code: "MW"
    },
    {
      dialCode: "+60",
      code: "MY"
    },
    {
      dialCode: "+960",
      code: "MV"
    },
    {
      dialCode: "+223",
      code: "ML"
    },
    {
      dialCode: "+356",
      code: "MT"
    },
    {
      dialCode: "+692",
      code: "MH"
    },
    {
      dialCode: "+596",
      code: "MQ"
    },
    {
      dialCode: "+222",
      code: "MR"
    },
    {
      dialCode: "+230",
      code: "MU"
    },
    {
      dialCode: "+262",
      code: "YT"
    },
    {
      dialCode: "+52",
      code: "MX"
    },
    {
      dialCode: "+691",
      code: "FM"
    },
    {
      dialCode: "+373",
      code: "MD"
    },
    {
      dialCode: "+377",
      code: "MC"
    },
    {
      dialCode: "+976",
      code: "MN"
    },
    {
      dialCode: "+382",
      code: "ME"
    },
    {
      dialCode: "+1664",
      code: "MS"
    },
    {
      dialCode: "+212",
      code: "MA"
    },
    {
      dialCode: "+258",
      code: "MZ"
    },
    {
      dialCode: "+95",
      code: "MM"
    },
    {
      dialCode: "+264",
      code: "NA"
    },
    {
      dialCode: "+674",
      code: "NR"
    },
    {
      dialCode: "+977",
      code: "NP"
    },
    {
      dialCode: "+31",
      code: "NL"
    },
    {
      dialCode: "+599",
      code: "CW"
    },
    {
      dialCode: "+687",
      code: "NC"
    },
    {
      dialCode: "+64",
      code: "NZ"
    },
    {
      dialCode: "+505",
      code: "NI"
    },
    {
      dialCode: "+227",
      code: "NE"
    },
    {
      dialCode: "+234",
      code: "NG"
    },
    {
      dialCode: "+683",
      code: "NU"
    },
    {
      dialCode: "+672",
      code: "NF"
    },
    {
      dialCode: "+1 670",
      code: "MP"
    },
    {
      dialCode: "+47",
      code: "NO"
    },
    {
      dialCode: "+968",
      code: "OM"
    },
    {
      dialCode: "+92",
      code: "PK"
    },
    {
      dialCode: "+680",
      code: "PW"
    },
    {
      dialCode: "+970",
      code: "PS"
    },
    {
      dialCode: "+507",
      code: "PA"
    },
    {
      dialCode: "+675",
      code: "PG"
    },
    {
      dialCode: "+595",
      code: "PY"
    },
    {
      dialCode: "+51",
      code: "PE"
    },
    {
      dialCode: "+63",
      code: "PH"
    },
    {
      dialCode: "+872",
      code: "PN"
    },
    {
      dialCode: "+48",
      code: "PL"
    },
    {
      dialCode: "+351",
      code: "PT"
    },
    {
      dialCode: "+1 939",
      code: "PR"
    },
    {
      dialCode: "+974",
      code: "QA"
    },
    {
      dialCode: "+40",
      code: "RO"
    },
    {
      dialCode: "+7",
      code: "RU"
    },
    {
      dialCode: "+250",
      code: "RW"
    },
    {
      dialCode: "+262",
      code: "RE"
    },
    {
      dialCode: "+590",
      code: "BL"
    },
    {
      dialCode: "+290",
      code: "SH"
    },
    {
      dialCode: "+1 869",
      code: "KN"
    },
    {
      dialCode: "+1 758",
      code: "LC"
    },
    {
      dialCode: "+590",
      code: "MF"
    },
    {
      dialCode: "+508",
      code: "PM"
    },
    {
      dialCode: "+1 784",
      code: "VC"
    },
    {
      dialCode: "+685",
      code: "WS"
    },
    {
      dialCode: "+378",
      code: "SM"
    },
    {
      dialCode: "+239",
      code: "ST"
    },
    {
      dialCode: "+966",
      code: "SA"
    },
    {
      dialCode: "+221",
      code: "SN"
    },
    {
      dialCode: "+381",
      code: "RS"
    },
    {
      dialCode: "+248",
      code: "SC"
    },
    {
      dialCode: "+232",
      code: "SL"
    },
    {
      dialCode: "+65",
      code: "SG"
    },
    {
      dialCode: "+421",
      code: "SK"
    },
    {
      dialCode: "+386",
      code: "SI"
    },
    {
      dialCode: "+677",
      code: "SB"
    },
    {
      dialCode: "+252",
      code: "SO"
    },
    {
      dialCode: "+27",
      code: "ZA"
    },
    {
      dialCode: "+500",
      code: "GS"
    },
    {
      dialCode: "+34",
      code: "ES"
    },
    {
      dialCode: "+94",
      code: "LK"
    },
    {
      dialCode: "+249",
      code: "SD"
    },
    {
      dialCode: "+597",
      code: "SR"
    },
    {
      dialCode: "+47",
      code: "SJ"
    },
    {
      dialCode: "+268",
      code: "SZ"
    },
    {
      dialCode: "+46",
      code: "SE"
    },
    {
      dialCode: "+41",
      code: "CH"
    },
    {
      dialCode: "+963",
      code: "SY"
    },
    {
      dialCode: "+886",
      code: "TW"
    },
    {
      dialCode: "+992",
      code: "TJ"
    },
    {
      dialCode: "+255",
      code: "TZ"
    },
    {
      dialCode: "+66",
      code: "TH"
    },
    {
      dialCode: "+670",
      code: "TL"
    },
    {
      dialCode: "+228",
      code: "TG"
    },
    {
      dialCode: "+690",
      code: "TK"
    },
    {
      dialCode: "+676",
      code: "TO"
    },
    {
      dialCode: "+1 868",
      code: "TT"
    },
    {
      dialCode: "+216",
      code: "TN"
    },
    {
      dialCode: "+90",
      code: "TR"
    },
    {
      dialCode: "+993",
      code: "TM"
    },
    {
      dialCode: "+1 649",
      code: "TC"
    },
    {
      dialCode: "+688",
      code: "TV"
    },
    {
      dialCode: "+256",
      code: "UG"
    },
    {
      dialCode: "+380",
      code: "UA"
    },
    {
      dialCode: "+971",
      code: "AE"
    },
    {
      dialCode: "+44",
      code: "GB"
    },
    {
      dialCode: "+1",
      code: "US"
    },
    {
      dialCode: "+598",
      code: "UY"
    },
    {
      dialCode: "+998",
      code: "UZ"
    },
    {
      dialCode: "+678",
      code: "VU"
    },
    {
      dialCode: "+58",
      code: "VE"
    },
    {
      dialCode: "+84",
      code: "VN"
    },
    {
      dialCode: "+1 284",
      code: "VG"
    },
    {
      dialCode: "+1 340",
      code: "VI"
    },
    {
      dialCode: "+681",
      code: "WF"
    },
    {
      dialCode: "+967",
      code: "YE"
    },
    {
      dialCode: "+260",
      code: "ZM"
    },
    {
      dialCode: "+263",
      code: "ZW"
    },
    {
      dialCode: "+358",
      code: "AX"
    }
  ];
  