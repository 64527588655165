import React, { useEffect, useState } from 'react'
import extractedIcon from '../../../../assets/svgs/extracted-icon.svg'
import userImg from '../../../../assets/sdk-report-user.png'
import faceAIImg from '../../../../assets/sdk-report-face-ai.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers'
import NotificationToast from '../../../utils/notifToast'
import { sdkReportSingleInfoRequest } from '../../../../redux/actions/sdkLibraries'
import { useParams } from 'react-router-dom'
import Mainloader, { FailedTag, SuccessTag, imgUrl, replaceUnderscores } from '../../../utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'


export default function SDKReportComp() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [activeTab, setActiveTab] = useState("Biometrics")


    const sdkReportSingleState = useSelector((state: RootState) => state.sdkReportSingleInfoReducer);

    const { ref } = useParams()
    const dispatch = useDispatch()


    useEffect(() => {
        getSingleReport()
    }, [])

    let getSingleReport = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                reference: ref
                // reference: "80b8131c-4393-4fb3-aeaa-2c3b98c031e5"
            },
            callback,
        };
        dispatch(sdkReportSingleInfoRequest(data))
    }

    // let getIdData = (data: any) => {
    //     if (data?.hasOwnProperty("bvn_data")) {
    //         return data.bvn_data
    //     }
    //     else if (data?.hasOwnProperty("nin_data")) {
    //         return data?.nin_data
    //     }
    //     else if (data?.hasOwnProperty("frsc_data")) {
    //         return data?.frsc_data
    //     }
    //     else if (data?.hasOwnProperty("cac_data")) {
    //         return data?.cac_data
    //     }
    //     else if (data?.hasOwnProperty("account_data")) {
    //         return data?.account_data
    //     }
    //     else if (data?.hasOwnProperty("email")) {
    //         return data?.email
    //     }
    //     else if (data?.hasOwnProperty("name")) {
    //         return data?.name
    //     }
    //     else if (data?.hasOwnProperty("screening_result")) {
    //         return data?.screening_result
    //     }
    //     else if (data?.hasOwnProperty("data")) {
    //         return data?.data
    //     }
    //     else if (data?.hasOwnProperty("data")) {
    //         return data?.data
    //     }
    //     return {}
    // }

    return (
        <div className='px-4 sdk-report-area'>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div>
                <h4>Results</h4>
            </div>

            {sdkReportSingleState?.isLoading && <Mainloader />}

            {!sdkReportSingleState?.isLoading &&
                <div>
                    <div className="d-flex my-3">
                        {["Biometrics", "Data"]?.map((val: any, i: number) => (
                            <div className={`${activeTab === val ? "border" : ""} rounded-4 me-3`} style={{ cursor: "pointer" }} key={i} onClick={() => setActiveTab(val)}>
                                <p className="px-3 py-1 m-0"> {val} </p>
                            </div>
                        ))}
                    </div>

                    {activeTab === "Biometrics" &&
                        <div className="row">

                            {sdkReportSingleState?.resp?.data?.extended_data?.length > 0 &&
                                <div className="col-md-5">
                                    <div className="card rounded-3 bg-white">
                                        <div className="card-body">
                                            <div className="section-label">
                                                <img src={extractedIcon} alt="" className='me-2' />
                                                <small>EXTRACTED DATA</small>
                                            </div>
                                            <div className="row mt-3 align-items-center">
                                                <div className="col-md-4">
                                                    <img src={sdkReportSingleState?.resp?.data?.metadata?.payload?.image} alt="" className='w-100 rounded-4' />
                                                </div>
                                                {/* <div className="col-md-8">
                                                <h6>Miles Anotninus Morales</h6>
                                                <small> M </small>
                                                <i className="ri-checkbox-blank-circle-fill" style={{ fontSize: "9px" }} />
                                                <small> 23 </small>
                                            </div> */}
                                            </div>
                                            <hr />

                                            <div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <small>
                                                            Confidence Level
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        This user's facial confidence
                                                                    </Tooltip>
                                                                }
                                                            ><i className='ri-information-line ri-lg ms-2' />
                                                            </OverlayTrigger>
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 text-md-end">
                                                        <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Confidence}%</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <small>
                                                            Age Range
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        This user's age range
                                                                    </Tooltip>
                                                                }
                                                            ><i className='ri-information-line ri-lg ms-2' />
                                                            </OverlayTrigger>
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 text-md-end">
                                                        <p>{`${sdkReportSingleState?.resp?.data?.extended_data[0]?.AgeRange?.Low} - ${sdkReportSingleState?.resp?.data?.extended_data[0]?.AgeRange?.High}`} years</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <small>
                                                            Gender
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        This user's gender
                                                                    </Tooltip>
                                                                }
                                                            ><i className='ri-information-line ri-lg ms-2' />
                                                            </OverlayTrigger>
                                                        </small>
                                                    </div>
                                                    <div className="col-md-6 text-md-end">
                                                        <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Gender?.Value}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div>
                                            <div className="section-label">
                                                <img src={extractedIcon} alt="" className='me-2' />
                                                <small>DATA FROM ID</small>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <small>Full Name</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>Miles Antoninus Morales</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>NIN</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>823445839292</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>BVN</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>823445839292</p>
                                                </div>
                                            </div>
                                        </div> */}

                                            <div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="section-label">
                                                        <img src={extractedIcon} alt="" className='me-2' />
                                                        <small>EMOTIONS</small>
                                                    </div>
                                                    <OverlayTrigger placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                This user's emotional count
                                                            </Tooltip>
                                                        }
                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                    </OverlayTrigger>
                                                </div>
                                                {sdkReportSingleState?.resp?.data?.extended_data[0]?.Emotions?.map((val: any, i: number) => (
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <small>{val?.Type}</small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <div className="rounded-3 w-100" style={{ height: "7px", backgroundColor: "#eee", }}>
                                                                <div className="rounded-3" style={{ height: "7px", backgroundColor: "#007da3", width: val?.Confidence + "%" }}>

                                                                </div>
                                                            </div>
                                                            <p>{val?.Confidence}%</p>
                                                        </div>
                                                    </div>

                                                ))}
                                                {/* <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <small>Happy</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Surprised</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Sad</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Calm</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Fear</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Angry</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Confused</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Disgusted</small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>56/100</p>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }



                            <div className="col-md-7">

                                {sdkReportSingleState?.resp?.data?.extended_data?.length > 0 &&
                                    <div className="card rounded-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6 rounded-4">
                                                    <img src={sdkReportSingleState?.resp?.data?.metadata?.payload?.image} alt="" className='w-100 rounded-4' />
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="section-label">
                                                            <img src={extractedIcon} alt="" className='me-2' />
                                                            <small>FACIAL FEATURES</small>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Eye
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Are this user's eyes open or closed?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.EyesOpen?.Value ? "Open" : "Close"}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Smiling
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Is this user smiling?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Smile?.Value ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Mouth
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Is this user's mouth open or closed?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.MouthOpen?.Value ? "Open" : "Close"}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Eye Glasses
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Is this user wearing glasses?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Eyeglasses?.Value ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Sunglasses
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Is this user wearing Sunglasses?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Sunglasses?.Value ? "Yes" : "No"}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Face Obstructed
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                is anything blocking this user's face?
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.FaceOccluded?.Value ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div>
                                                    <div className="section-label">
                                                        <img src={extractedIcon} alt="" className='me-2' />
                                                        <small>NETWORK DETAILS</small>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Registry
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            User's network registry
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.registry}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Registry Status
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            User's network registry status
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.registry_status}</p>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <small className='mb-0'>
                                                                Registered Country
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            User's network country
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-5 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.registered_country}</p>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <small className='mb-0'>
                                                                Globally Reachable
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            User's network reachability
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-5 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.is_reachable_globally ? "Yes" : "No"}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                A Web Proxy
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Is this user on a web proxy?
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.is_web_proxy ? "Yes" : "No"}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Tor Usage
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Is this user using Tor browser?
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.is_tor ? "Yes" : "No"}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                VPN Usage
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Is this user on VPN?
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.network_details?.is_vpn ? "Yes" : "No"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        {sdkReportSingleState?.resp?.data?.extended_data?.length > 0 &&
                                            <div className="card mt-3">
                                                <div className="card-body">
                                                    <div>
                                                        <div className="section-label">
                                                            <img src={extractedIcon} alt="" className='me-2' />
                                                            <small>IMAGE QUALITY</small>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Brightness
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                User's image brightness
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <div className="rounded-3 w-100" style={{ height: "7px", backgroundColor: "#eee", }}>
                                                                    <div className="rounded-3" style={{
                                                                        height: "7px", backgroundColor: "#007da3",
                                                                        width: sdkReportSingleState?.resp?.data?.extended_data[0]?.Quality?.Brightness + "%"
                                                                    }}
                                                                    />
                                                                </div>
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Quality?.Brightness}%</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <small>
                                                                    Sharpness
                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                User's image sharpness
                                                                            </Tooltip>
                                                                        }
                                                                    ><i className='ri-information-line ri-lg ms-2' />
                                                                    </OverlayTrigger>
                                                                </small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <div className="rounded-3 w-100" style={{ height: "7px", backgroundColor: "#eee", }}>
                                                                    <div className="rounded-3" style={{
                                                                        height: "7px", backgroundColor: "#007da3",
                                                                        width: sdkReportSingleState?.resp?.data?.extended_data[0]?.Quality?.Sharpness + "%"
                                                                    }}
                                                                    />
                                                                </div>
                                                                <p>{sdkReportSingleState?.resp?.data?.extended_data[0]?.Quality?.Sharpness}%</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div>
                                                    <div className="section-label">
                                                        <img src={extractedIcon} alt="" className='me-2' />
                                                        <small>USER DATA</small>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-6">
                                                            <small>
                                                                Date
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Date verification was made
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{moment(sdkReportSingleState?.resp?.data?.widget_details?.report_created).format('LL')}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small>
                                                                Time
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Time verification was made
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{moment(sdkReportSingleState?.resp?.data?.widget_details?.report_created).format('LT')} GMT</p>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                                            <small>
                                                                Phone Model
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Your gender
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2'/>
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>IPhone 12</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div>
                                                    <div className="section-label">
                                                        <img src={extractedIcon} alt="" className='me-2' />
                                                        <small>LOCATION DATA</small>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Continent
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Continent verification was made from
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.ip_details?.continent}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Country
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Country verification was made from
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.ip_details?.country}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                City
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            City verification was made from
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.ip_details?.city}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Longitude
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Longitude of user's location
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.ip_details?.longitude}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <small className='mb-0'>
                                                                Latitude
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Latitude of user's location
                                                                        </Tooltip>
                                                                    }
                                                                ><i className='ri-information-line ri-lg ms-2' />
                                                                </OverlayTrigger>
                                                            </small>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p>{sdkReportSingleState?.resp?.data?.ip_report?.ip?.ip_details?.latitude}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {activeTab === "Data" &&
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card rounded-3 bg-white">
                                    <div className="card-body">
                                        <div className="section-label">
                                            <img src={extractedIcon} alt="" className='me-2' />
                                            <small>USER INFORMATION</small>
                                        </div>
                                        <div className=" mt-3">
                                            {/* <div className="col-md-4"> */}
                                            {sdkReportSingleState?.resp?.data?.data_report && Object?.keys(sdkReportSingleState?.resp?.data?.data_report)?.map((val: any, i: number) => {
                                                if ((typeof sdkReportSingleState?.resp?.data?.data_report[val] === 'string') && (sdkReportSingleState?.resp?.data?.data_report[val]?.length > 500) && (sdkReportSingleState?.resp?.data?.data_report[val] !== "signature")) {
                                                    return (
                                                        <div className="col-md-4" key={i}>
                                                            <img className="rounded-4" alt="Canditate" style={{ height: "90px", width: "90px" }}
                                                                src={imgUrl(sdkReportSingleState?.resp?.data?.data_report[val])}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            })}

                                            {/* <img src={sdkReportSingleState?.resp?.data?.metadata?.payload?.image} alt="" className='w-100 rounded-4' /> */}
                                            {/* </div> */}

                                            {/* <div className="col-md-12 mt-3">
                                                <h6>Miles Anotninus Morales</h6>
                                            </div> */}
                                        </div>
                                        <hr />

                                        <div>
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <small>
                                                        Endpoint
                                                        <OverlayTrigger placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    Verification Endpoint
                                                                </Tooltip>
                                                            }
                                                        ><i className='ri-information-line ri-lg ms-2' />
                                                        </OverlayTrigger>
                                                    </small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    <p>{sdkReportSingleState?.resp?.data?.widget_details?.endpoint_code}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>
                                                        Status
                                                        <OverlayTrigger placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    Verification Status
                                                                </Tooltip>
                                                            }
                                                        ><i className='ri-information-line ri-lg ms-2' />
                                                        </OverlayTrigger>
                                                    </small>
                                                </div>
                                                <div className="col-md-6 text-md-end">
                                                    {sdkReportSingleState?.resp?.data?.widget_details?.data_request_status === "00" ?
                                                        <SuccessTag />
                                                        :
                                                        <FailedTag />
                                                    }
                                                </div>
                                            </div>
                                            {Object.keys(sdkReportSingleState?.resp?.data?.metadata?.payload)?.map((val: any, i: number) => {
                                                if (val !== "image") {
                                                    return (
                                                        <div className="row" key={i}>
                                                            <div className="col-md-6">
                                                                <small>{val}</small>
                                                            </div>
                                                            <div className="col-md-6 text-md-end">
                                                                <p>{sdkReportSingleState?.resp?.data?.metadata?.payload[val]}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="card rounded-3 bg-white">
                                    <div className="card-body">
                                        <div className="section-label">
                                            <img src={extractedIcon} alt="" className='me-2' />
                                            <small>VERIFICATION DATA</small>
                                        </div>

                                        <div>
                                            {sdkReportSingleState?.resp?.data?.data_report &&
                                                <>
                                                    {/* Image starts here */}
                                                    {Object?.keys(sdkReportSingleState?.resp?.data?.data_report)?.map((val: any, i: number) => {
                                                        if ((typeof sdkReportSingleState?.resp?.data?.data_report[val] === 'string') && (sdkReportSingleState?.resp?.data?.data_report[val]?.length > 500) && (sdkReportSingleState?.resp?.data?.data_report[val] !== "signature")) {
                                                            return (
                                                                <div className="col-md-4 my-3">
                                                                    <img className="response-img rounded-4" alt="Canditate" style={{ width: "150px", height: "150px", }}
                                                                        src={imgUrl(sdkReportSingleState?.resp?.data?.data_report[val])}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                    {/* Image ends here */}

                                                    {/* other responses */}
                                                    {/* <div className='row'> */}
                                                    {Object?.keys(sdkReportSingleState?.resp?.data?.data_report)?.map((val: any, i: number) => {
                                                        if ((typeof sdkReportSingleState?.resp?.data?.data_report[val] === 'string') && (sdkReportSingleState?.resp?.data?.data_report[val]?.length < 500)) {
                                                            return (
                                                                <div className='row' key={i}>
                                                                    <div className="col-md-6">
                                                                        <small style={{ color: "#848b95" }}>{replaceUnderscores(val, "upper")}</small>
                                                                    </div>
                                                                    <div className="col-md-6 text-md-end">
                                                                        <p>{(sdkReportSingleState?.resp?.data?.data_report[val] || "Not Available")}</p>
                                                                    </div>
                                                                    {/* <div className={`col-md-6 ${(i + 1) % 2 === 1 ? "" : "text-md-end"}`} key={i}>
                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(val, "upper")}</small>
                                                                            <p>{(sdkReportSingleState?.resp?.data?.data_report[val] || "Not Available")}</p>
                                                                        </div> */}
                                                                </div>
                                                            )
                                                        }

                                                        // if ((typeof newVal === 'boolean') && key === "valid" ) {
                                                        // 	return (
                                                        // 		<div className="col-md-4">
                                                        // 			<small style={{color:"#848b95"}}>Validity</small>
                                                        // 			<p>
                                                        // 				{(newVal === true && key === "valid") ? <ValidTag/> :
                                                        // 					(newVal === false && key === "valid") ? <InvalidTag/> : ""
                                                        // 			 		// (newVal || "Not Available")
                                                        // 				}
                                                        // 			</p>
                                                        // 		</div>
                                                        // 	)
                                                        // }

                                                        if ((Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val]) && sdkReportSingleState?.resp?.data?.data_report[val]?.length > 0)) {
                                                            return (
                                                                <div className="col-md-12 mt-3">
                                                                    <div className="row">

                                                                        {sdkReportSingleState?.resp?.data?.data_report[val]?.map((newNewVal: any, ind: number) => {
                                                                            if (newNewVal && typeof newNewVal === 'string') {
                                                                                return (
                                                                                    <div>
                                                                                        <div className='row'>
                                                                                            <div className="col-md-6">
                                                                                                <small style={{ color: "#848b95" }}>{replaceUnderscores(val, "upper")}</small>
                                                                                            </div>
                                                                                            <div className="col-md-6 text-md-end">
                                                                                                <p>{newNewVal || "Not Available"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            if (newNewVal &&
                                                                                !Array.isArray(newNewVal) &&
                                                                                typeof newNewVal === 'object') {
                                                                                return (
                                                                                    <div className="">
                                                                                        <small className='bg-light p-2' style={{ color: "#848b95" }}>{replaceUnderscores(val, "upper")}</small>
                                                                                        {newNewVal && Object?.entries(newNewVal)?.map(([levelTwoDataKey, levelTwoDataNewVal]: any) => {
                                                                                            if (newNewVal[levelTwoDataKey] &&
                                                                                                typeof newNewVal[levelTwoDataKey] === 'string') {
                                                                                                return (
                                                                                                    <div className='row'>
                                                                                                        <div className="col-md-6">
                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelTwoDataKey, "upper")}</small>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                            <p>{levelTwoDataNewVal || "Not Available"}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                        <hr />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            // <div className="row">
                                                                            // 	{Object?.entries(newNewVal)?.map(([dataKey, dataNewVal]: any) => (
                                                                            // 		<div className="col-md-4">
                                                                            // 			<small style={{color:"#848b95"}}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                            // 			<p>{ (typeof dataNewVal === "string") &&  (dataNewVal  || "Not Available")}</p>
                                                                            // 		</div>
                                                                            // 	))}
                                                                            // </div>
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        
                                                        if ((!Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val]) && typeof sdkReportSingleState?.resp?.data?.data_report[val] === 'object')) {
                                                            return (
                                                                <div className="col-md-12 mt-3">

                                                                    <div className="row">
                                                                        {Object?.entries(sdkReportSingleState?.resp?.data?.data_report[val])?.map(([dataKey, dataNewVal]: any) => {

                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey] && typeof sdkReportSingleState?.resp?.data?.data_report[val][dataKey] === 'string') {
                                                                                return (
                                                                                    <div className='row'>
                                                                                        <div className="col-md-6">
                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                                        </div>
                                                                                        <div className="col-md-6 text-md-end">
                                                                                            <p>{dataNewVal || "Not Available"}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }


                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey] &&
                                                                                Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val][dataKey]) ) {
                                                                                return (
                                                                                    <div className="">
                                                                                        <small className='bg-light p-2' style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>

                                                                                        {sdkReportSingleState?.resp?.data?.data_report[val][dataKey] && sdkReportSingleState?.resp?.data?.data_report[val][dataKey]?.map((levelTwoData: any, ind:any) => {
                                                                                            if (levelTwoData && typeof levelTwoData === 'string') {
                                                                                                return (
                                                                                                    <div className='row'>
                                                                                                        <div className="col-md-6">
                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                            <p>{levelTwoData || "Not Available"}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            if (levelTwoData && Array.isArray(levelTwoData)) {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {levelTwoData && levelTwoData?.map((levelThreeData: any, ind:any) => {
                                                                                                            if (levelThreeData && typeof levelThreeData === 'string') {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className='row'>
                                                                                                                            <div className="col-md-6">
                                                                                                                                <small style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 text-md-end">
                                                                                                                                <p>{levelThreeData || "Not Available"}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            if (levelThreeData &&
                                                                                                                !Array.isArray(levelThreeData) &&
                                                                                                                typeof levelThreeData === 'object') {
                                                                                                                return (
                                                                                                                    <div className="">
                                                                                                                        <small className='bg-light p-2' style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                                                                        {levelThreeData && Object?.entries(levelThreeData)?.map(([levelThreeDataKey, levelThreeDataNewVal]: any) => {
                                                                                                                            if (levelThreeData[levelThreeDataKey] &&
                                                                                                                                typeof levelThreeData[levelThreeDataKey] === 'string') {
                                                                                                                                return (
                                                                                                                                    <div className='row'>
                                                                                                                                        <div className="col-md-6">
                                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelThreeDataKey, "upper")}</small>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                                            <p>{levelThreeDataNewVal || "Not Available"}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })}
                                                                                                                        {/* <hr /> */}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            

                                                                                            if (levelTwoData &&
                                                                                                !Array.isArray(levelTwoData) &&
                                                                                                typeof levelTwoData === 'object') {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {levelTwoData && Object?.entries(levelTwoData)?.map(([levelThreeDataKey, levelThreeDataNewVal]: any) => {
                                                                                                            // console.log("level three hvjkvjhvjg",levelTwoData[levelThreeDataKey])
                                                                                                            if (levelTwoData[levelThreeDataKey] &&
                                                                                                                typeof levelTwoData[levelThreeDataKey] === 'string') {
                                                                                                                return (
                                                                                                                    <div className='row'>
                                                                                                                        <div className="col-md-6">
                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelThreeDataKey, "upper")}</small>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                            <p>{levelThreeDataNewVal || "Not Available"}</p>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                )
                                                                                                            }                                                                

                                                                                                            if (levelTwoData[levelThreeDataKey] &&
                                                                                                                Array.isArray(levelTwoData[levelThreeDataKey]) ) {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {levelTwoData[levelThreeDataKey]?.map((levelFourData: any) => {
                                                                                                                            if (levelFourData  &&
                                                                                                                                typeof levelFourData === 'string') {
                                                                                                                                return (
                                                                                                                                    <div className='row'>
                                                                                                                                        <div className="col-md-6">
                                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelThreeDataKey, "upper")}</small>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                                            <p>{levelFourData || "Not Available"}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                            if (levelTwoData[levelThreeDataKey] &&
                                                                                                                !Array.isArray(levelTwoData[levelThreeDataKey]) &&
                                                                                                                typeof levelTwoData[levelThreeDataKey] === 'object') {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        {levelTwoData[levelThreeDataKey] && Object?.entries(levelTwoData[levelThreeDataKey])?.map(([levelFourDataKey, levelFourDataNewVal]: any) => {
                                                                                                                            if (levelTwoData[levelThreeDataKey][levelFourDataKey]  &&
                                                                                                                                typeof levelTwoData[levelThreeDataKey][levelFourDataKey] === 'string') {
                                                                                                                                return (
                                                                                                                                    <div className='row'>
                                                                                                                                        <div className="col-md-6">
                                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelFourDataKey, "upper")}</small>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                                            <p>{levelFourDataNewVal || "Not Available"}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                        <hr />
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey] &&
                                                                                !Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val][dataKey]) &&
                                                                                typeof sdkReportSingleState?.resp?.data?.data_report[val][dataKey] === 'object') {
                                                                                return (
                                                                                    <div className="">
                                                                                        <small className='bg-light p-2' style={{ color: "#848b95" }}>{replaceUnderscores(dataKey, "upper")}</small>
                                                                                        {sdkReportSingleState?.resp?.data?.data_report[val][dataKey] && Object?.entries(sdkReportSingleState?.resp?.data?.data_report[val][dataKey])?.map(([levelTwoDataKey, levelTwoDataNewVal]: any) => {
                                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] &&
                                                                                                typeof sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] === 'string') {
                                                                                                return (
                                                                                                    <div className='row'>
                                                                                                        <div className="col-md-6">
                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelTwoDataKey, "upper")}</small>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                            <p>{levelTwoDataNewVal || "Not Available"}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] &&
                                                                                                Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey])) {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] && sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey]?.map((levelThreeData: any, ind:any) => {
                                                                                                            if (levelThreeData && typeof levelThreeData === 'string') {
                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className='row'>
                                                                                                                            <div className="col-md-6">
                                                                                                                                <small style={{ color: "#848b95" }}>{replaceUnderscores(levelTwoDataKey, "upper")}</small>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 text-md-end">
                                                                                                                                <p>{levelThreeData || "Not Available"}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            if (levelThreeData &&
                                                                                                                !Array.isArray(levelThreeData) &&
                                                                                                                typeof levelThreeData === 'object') {
                                                                                                                return (
                                                                                                                    <div className="">
                                                                                                                        <small className='bg-light p-2' style={{ color: "#848b95" }}>{replaceUnderscores(levelTwoDataKey, "upper")}</small>
                                                                                                                        {levelThreeData && Object?.entries(levelThreeData)?.map(([levelThreeDataKey, levelThreeDataNewVal]: any) => {
                                                                                                                            if (levelThreeData[levelThreeDataKey] &&
                                                                                                                                typeof levelThreeData[levelThreeDataKey] === 'string') {
                                                                                                                                return (
                                                                                                                                    <div className='row'>
                                                                                                                                        <div className="col-md-6">
                                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelThreeDataKey, "upper")}</small>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                                            <p>{levelThreeDataNewVal || "Not Available"}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })}
                                                                                                                        {/* <hr /> */}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                             

                                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] &&
                                                                                                !Array.isArray(sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey]) &&
                                                                                                typeof sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] === 'object') {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey] && Object?.entries(sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey])?.map(([levelThreeDataKey, levelThreeDataNewVal]: any) => {
                                                                                                            // console.log("level three hvjkvjhvjg",sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey][levelThreeDataKey])
                                                                                                            if (sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey][levelThreeDataKey] &&
                                                                                                                typeof sdkReportSingleState?.resp?.data?.data_report[val][dataKey][levelTwoDataKey][levelThreeDataKey] === 'string') {
                                                                                                                return (
                                                                                                                    <div className='row'>
                                                                                                                        <div className="col-md-6">
                                                                                                                            <small style={{ color: "#848b95" }}>{replaceUnderscores(levelThreeDataKey, "upper")}</small>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-6 text-md-end">
                                                                                                                            <p>{levelThreeDataNewVal || "Not Available"}</p>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                        <hr />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                    {/* </div> */}
                                                </>
                                            }

                                            {/* <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <small>Full Name</small>
                                                    <p>Miles Antoninus Morales</p>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <small>Age</small>
                                                    <p>23 years</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Gender</small>
                                                    <p>Male</p>
                                                </div>

                                                <div className="col-md-6 ">
                                                    <small>NIN</small>
                                                    <p>823445839292</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>BVN</small>
                                                    <p>823445839292</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>Full Name</small>
                                                    <p>Miles Antoninus Morales</p>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <small>NIN</small>
                                                    <p>823445839292</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <small>BVN</small>
                                                    <p>823445839292</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    }
                </div>
            }

        </div >
    )
}
