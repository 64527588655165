import axios from "axios";
import Cookies from "js-cookie";
let accessT = Cookies.get("babtbu") || ""
let orgId = Cookies.get("org") || ""
let tenantId = Cookies.get("btbibd") || ""




export const createApiBuilderEndpointAPi =async (data:any)=>{

    const resp =await  axios.post('https://api.prembly.com/identitypass/builder/endpoint-create',data,{
        headers:{
            Authorization:accessT,
            organisation:orgId
        }
    })
    return resp.data
}