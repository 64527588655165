import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../../constants/actionTypes"
import Cookies from "js-cookie";
import { authorizationRedirect, serverCodes } from "../../constants/api";
import global from "../../constants/global";
import { IAllPremblyProducts } from "../../actions/allPremblyProducts/types";
import { allPremblyProductsFailure, allPremblyProductsSuccess } from "../../actions/allPremblyProducts";

let accessT = Cookies.get("babtbu") || ""
let orgId = Cookies.get("org") || ""
let tenantId = Cookies.get("btbibd") || ""

const allPremblyProducts = async (payload: any) => {
  const { data } = await axios.get<IAllPremblyProducts[]>(
    global.apiBaseUrl + global.liveUrl + "api/v1/products/list",
    // payload,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accessT,
        Tenant: tenantId,
        Organisation: orgId,
      },
    }
  );
  return data;
};


function* allPremblyProductsSaga(action: any) {
  try {
    const response: { data: any } = yield call(allPremblyProducts, {
      // email: action.payload.values.email,
      // session: action.payload.values.session,
    });

    yield put(
      allPremblyProductsSuccess({
        resp: response,
      })
    );
    action.payload.callback(response);
  } catch (e: any) {
    if(serverCodes.includes(e?.response?.request?.status) || !e?.response?.request?.status){
      yield put(
        allPremblyProductsFailure({
          error: "An error occurred, hang on a minute as we work towards fixing this error.",
        })
      );
      action.payload.callback(
        { detail:"An error occurred, hang on a minute as we work towards fixing this error." }
      );
      return
    }
    if (e.response.request.status === 401) {
      authorizationRedirect()
    } else {
      yield put(
        allPremblyProductsFailure({
          error: e.response.data.detail,
        })
      );
      action.payload.callback(e.response.data);
    }
  }
}


export function* allPremblyProductsSagaTrigger() {
  yield all([takeLatest(actionTypes.ALL_PREMBLY_PRODUCTS_REQUEST, allPremblyProductsSaga)]);
}
