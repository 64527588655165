import React, { useState } from 'react'
import './styles/styles.css'
import { Link } from 'react-router-dom'
import TemplateCard from './TemplateCard'
import { templateCollection, templates as defaultTemplates } from '../../data'
import { toast, Toaster } from 'react-hot-toast'
import Barrow from '../../assets/arrow-left.png'

export default function GeneralApiTemplate({ templates: initialTemplates = defaultTemplates }) {
    const [filters, setFilters] = useState({
        inquiryType: [],
        country: '',
        endpoint: '',
    })
    const [showFilters, setShowFilters] = useState(false)

    const handleFilterChange = (e) => {
        const { name, value, checked } = e.target
        if (name === 'inquiryType') {
            setFilters((prevFilters) => ({
                ...prevFilters,
                inquiryType: checked
                    ? [...prevFilters.inquiryType, value]
                    : prevFilters.inquiryType.filter((type) => type !== value),
            }))
        } else {
            setFilters({
                ...filters,
                [name]: value,
            })
        }
    }

    const applyFilters = () => {
        const filteredTemplates = templateCollection.filter((template) => {
            const matchesInquiryType =
                filters.inquiryType.length === 0 ||
                filters.inquiryType.includes(template.inquiryType)
            const matchesCountry = !filters.country || template.country === filters.country
            const matchesEndpoint =
                !filters.endpoint || template.endpoint.includes(filters.endpoint)
            return matchesInquiryType && matchesCountry && matchesEndpoint
        })

        return filteredTemplates
    }

    const filteredTemplates = applyFilters()

    // Extracting unique country values from templateCollection
    const uniqueCountries = Array.from(
        new Set(templateCollection.map((template) => template.country))
    )

    return (
        <div className="dashboard-area vh-100 mb-4">
            <Toaster />
            <div className="container-fluid h-100 px-md-4 my-4">
                <div className="row h-100">
                    <div className="col-md-8 d-flex flex-column">
                        <Link className="greyBG go-back-btn" to="/apibuilder">
                            <img src={Barrow} alt="Go Back" />
                            Go Back
                        </Link>
                        {/* API Template */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <h5 className="card-title">API Templates</h5>
                            <button
                                className="btn btn-primary"
                                onClick={() => setShowFilters(!showFilters)}>
                                Filter
                            </button>
                        </div>
                        {showFilters && (
                            <div className="filter-container mt-4">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Inquiry Type</label>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="inquiryType"
                                                value="kyc"
                                                onChange={handleFilterChange}
                                            />
                                            <label>KYC</label>
                                        </div>
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="inquiryType"
                                                value="kyb"
                                                onChange={handleFilterChange}
                                            />
                                            <label>KYB</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Country</label>
                                        <select name="country" onChange={handleFilterChange}>
                                            <option value="">All</option>
                                            {uniqueCountries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Endpoint</label>
                                        <input
                                            type="text"
                                            name="endpoint"
                                            value={filters.endpoint}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                                <button className="btn btn-secondary mt-3" onClick={applyFilters}>
                                    Apply Filters
                                </button>
                            </div>
                        )}
                        <div className="row mt-4 temp">
                            {filteredTemplates.map((template, index) => (
                                <div key={index} className="col-md-4" style={{ padding: '7px' }}>
                                    <TemplateCard template={template} />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="col-md-4 mb-5">
                        <div
                            className="card w-100"
                            style={{
                                borderRadius: '10px',
                            }}>
                            <div className="card-body"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
