import React, { useEffect, useRef, useState } from 'react'
import { EmptyStateComp, replaceUnderscores } from '../../components/utils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { reportAnalysisHistoryRequest, reportAnalysisRequest } from '../../redux/actions/aiReportAnalysis'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import sendIcon from '../../assets/send.svg'
import micIcon from '../../assets/svgs/microphone.svg'
import imgIcon from '../../assets/svgs/Image.svg'



// let previousRequests: any = [
// 	{
// 		day: 'Previous 7 days',
// 		title: [
// 			'Give me new customer signups for the past 1 month',
// 			'Give me new customer signups for the past 1 month',
// 			'Give me new customer signups for the past 1 month',
// 		]
// 	},
// ]

let suggestionsLists: any = [
	'Give me a list of my recent customers',
	'Give me a list of my most active customers',
]
// const tooltip = (
// 	<Tooltip id="tooltip">
// 	  <strong>Holy guacamole!</strong> Check this info.
// 	</Tooltip>
// );

export default function AIReportAnalysisPage() {
	// interface IreportResponse {
	//     query: any
	//     res: any
	// }
	const [notifVal, setNotifVal] = useState(false)
	const [notif, setNotif] = useState('')
	const [notifTitle, setNotifTitle] = useState('')
	const [analysisInput, setAnalysisInput] = useState("")
	const [reportResponse, setReportResponse] = useState<any>([])
	const [activeSuggestion, setActiveSuggestion] = useState("")

	const reportAnalysisState = useSelector((state: RootState) => state.reportAnalysisReducer)

	const dummy = useRef<null | any>(null);
	const dispatch = useDispatch()

	useEffect(() => {
		getAnalysisHistory()
	}, [])

	useEffect(() => {
		dummy?.current?.scrollIntoView({
			behavior: "smooth",
		});
	}, [reportResponse?.length])

	let getAnalysisHistory = () => {
		const callback = (data: any) => {
			if (data.status) {
				console.log(data)
			}
			else {
				setNotifTitle('Error')
				setNotif(data.detail)
				setNotifVal(true)
			}
		}
		let data: any = {
			values: {},
			callback,
		}
		dispatch(reportAnalysisHistoryRequest(data))
	}

	let runAnalysis = (e: any) => {
		e.preventDefault()
		let requestResponse: any = reportResponse

		const callback = (data: any) => {
			if (data.status) {
				requestResponse.push({ query: analysisInput, res: data?.data })
				setReportResponse([...requestResponse])
				setAnalysisInput("")
				// console.log(data)
			}
			else {
				setNotifTitle('Error')
				setNotif(data.detail)
				setNotifVal(true)
			}
		}
		let data: any = {
			values: {
				query: analysisInput,
				product: "identitypass"
			},
			callback,
		}
		dispatch(reportAnalysisRequest(data))
	}

	let runDataAnalysis = (queryVal: any) => {
		let requestResponse: any = reportResponse

		const callback = (data: any) => {
			if (data.status) {
				requestResponse.push({ query: queryVal, res: data?.data })
				setReportResponse([...requestResponse])
				setAnalysisInput("")
				setActiveSuggestion("")
				// console.log(data)
			}
			else {
				setActiveSuggestion("")
				setNotifTitle('Error')
				setNotif(data.detail)
				setNotifVal(true)
			}
		}
		let data: any = {
			values: {
				query: queryVal,
				product: "identitypass"
			},
			callback,
		}
		dispatch(reportAnalysisRequest(data))
	}


	return (
		<div className=''>
			{/* <div className="px-4 mb-4">
				<div className=" table-header">
					<div className="col-md-5">
						<h5>AI Report Analysis</h5>
						<p>
							Report Analysis powered by AI
						</p>
					</div>
				</div>
			</div> */}

			{/* <div className=' px-4'>
				<div className="card text-white border-0" style={{backgroundColor:"#37b7ab"}}>
					<div className="card-body">
						<div className="row">
							<div className="col-md-11">
								<div className="d-flex ">
									<img src="" alt="" />
									<p>This feature is still in test mode and may take some time to provide instant results</p>
								</div>
							</div>
							<div className="col-md-1 text-end">
								<i className="ri-close-line ri-xl"/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="row m-md-0 ">
				{/* <div className="col-md-9"> */}
				<div className="col-md-11">
					<div className="ps-md-3">
						{/* <div className="card rounded-0 " style={{border:"1px solid #dfdfdf"}}> */}
						<div className=" rounded-0 ">
							<div className="card-body">
								<div className=''>
									<div style={{ height: "73vh", overflowY: "scroll" }}>
										{reportResponse?.length < 1 &&
											<div className="py-5 col-11 col-md-11 col-lg-9 mx-auto">
												<div className="card rounded-4 border-0 px-3 py-4" style={{ backgroundColor: "#DAF1EE" }}>
													<div className="card-body">
														<h4>How can I help you today?</h4>
														<p>
															Ask our AI assistant and get clear, personalised
															feedback and reports on your usage patterns in just seconds.
														</p>
														<form onSubmit={runAnalysis}>
															<div className='d-flex align-items-center mt-3'>
																<input
																	onChange={(e) => setAnalysisInput(e?.target?.value)}
																	value={analysisInput}
																	className="w-100 form-control border-0"
																	placeholder="Enter a prompt here"
																	style={{ height: "50px", borderRadius: "15px 0px 0px 15px" }}
																	disabled={reportAnalysisState?.isLoading}
																/>
																<div className="bg-white px-2 py-2" style={{ height: "50px" }}>
																	<OverlayTrigger placement="top"
																		overlay={
																			<Tooltip>
																				Coming soon
																			</Tooltip>
																		}
																	>
																		<img
																			style={{ cursor: "pointer" }}
																			src={micIcon} alt=""
																		/>
																	</OverlayTrigger>
																</div>
																<div className="bg-white px-2 py-2" style={{ height: "50px" }}>
																	<OverlayTrigger placement="top"
																		overlay={
																			<Tooltip>
																				Coming soon
																			</Tooltip>
																		}
																	>
																		<img
																			style={{ cursor: "pointer" }}
																			src={imgIcon} alt=""
																		/>
																	</OverlayTrigger>
																</div>
																<div className="bg-white px-2 py-2 " style={{ height: "50px", borderRadius: "0px 15px 15px 0px" }}>
																	{analysisInput && (
																		<>
																			{reportAnalysisState?.isLoading ? (
																				<Spinner
																					as="span"
																					animation="border"
																					size="sm"
																					role="status"
																					aria-hidden="true"
																				/>
																			) : (
																				<img
																					onClick={runAnalysis}
																					style={{ cursor: "pointer" }}
																					src={sendIcon} alt=""
																				/>
																			)}
																		</>
																	)}
																</div>
															</div>
														</form>
													</div>
												</div>
												<div className="row">
													{suggestionsLists?.map((val: any, i: number) => (
														<div className="col-md-6 mt-3" key={i}>
															<div className="card rounded-4"
																onClick={
																	reportAnalysisState?.isLoading ? () => { } :
																		() => {
																			setActiveSuggestion(val)
																			runDataAnalysis(val)
																		}
																} style={{ cursor: "pointer" }}
															>
																<div className="card-body">
																	<div className="d-flex">
																		<div className="col-11">
																			<small>{val}</small>
																		</div>
																		<div className="col-1">
																			{((reportAnalysisState?.isLoading) && (activeSuggestion === val)) ? (
																				<Spinner
																					as="span"
																					animation="border"
																					size="sm"
																					role="status"
																					aria-hidden="true"
																				/>
																			) : (
																				<img src={sendIcon} alt="" />
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										}

										{reportResponse?.length > 0 &&
											<div className='col-11 col-md-11 col-lg-9 mx-auto'>
												{reportResponse?.map((val: any, i: number) => (
													<div key={i} className='mt-4'>
														<div className="d-flex mb-2">
															<div style={{ width: "50px", height: "50px", background: "#eee", borderRadius: "50%" }} />
															<h6 style={{ fontSize: "15px" }} className='mt-3 ms-2'>{replaceUnderscores(val?.query, "upper")}</h6>
														</div>
														{(val?.res && Array.isArray(val?.res)) && val?.res?.map((newVal: any, k: number) => (
															<div className='py-3 px-3 mt-3 bg-light rounded-4' key={k}>
																<div className="row">
																	{Object.entries(newVal).map(([key, value]: [string, any], entryIndex: number) => {
																		if ((key !== "id") && (key !== "tenant") && (key !== "updated_at") && (key !== "organisation") && key !== "created_at") {
																			return (
																				<div key={entryIndex} className='col-md-3'>
																					<small style={{ fontSize: "12px", fontWeight: "500" }}>{replaceUnderscores(key, "upperFirstAll")}</small>
																					<p style={{ fontSize: "13px" }}> {value}</p>
																				</div>
																			)
																		}
																	})}
																</div>
															</div>
														))}

													</div>

												))}
												<div ref={dummy} />

											</div>
										}
									</div>
									<div className="col-md-11 col-lg-9 mx-auto mb-3">
										{reportResponse?.length > 0 &&
											<div>
												<form onSubmit={runAnalysis}>
													<div className='d-flex align-items-center mt-3'>
														<input
															type="text"
															onChange={(e) => setAnalysisInput(e?.target?.value)}
															value={analysisInput}
															className="w-100 form-control rounded-4"
															// rows={1}
															placeholder="Enter a prompt here"
															disabled={reportAnalysisState?.isLoading}
															style={{ height: "50px" }}
														/>
														{analysisInput && (
															<>
																{reportAnalysisState?.isLoading ? (
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>
																) : (

																	<img
																		onClick={runAnalysis}
																		style={{ cursor: "pointer" }}
																		src={sendIcon} alt=""
																	/>
																	// <i
																	// 	onClick={runAnalysis}
																	// 	style={{ cursor: "pointer" }}
																	// 	className="ri-send-plane-2-fill ri-xl mx-2"
																	// />
																)}

															</>
														)}
													</div>
												</form>
											</div>
										}
									</div>

									{/* <div className=''>
										<input type="text" className='form-control' placeholder="Enter a prompt here" />
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="col-md-3">
					<div className="card rounded-0 " style={{ minHeight: "85.7vh", border:"1px solid #dfdfdf" }}>
						<div className="card-body">

							<small style={{fontSize:"13px", fontWeight:"500"}}>Previous 7 days</small>
							<p style={{fontSize:"14px", fontWeight:"500"}} className='add-ellipsis'>Give me new customer signups for the past 1 month</p>

						</div>
					</div>
				</div> */}
			</div>
		</div>
	)
}
