// data.ts

export interface Template {
    name: string
    date: string
    createdBy: string
    status: 'PUBLISHED' | 'DRAFTS'
    country: string
    link: string
    endpoints: string[]
    inquiryType: 'KYC' | 'KYB'
}

export const templateCollection: Template[] = [
    {
        name: 'GTB Customer API Template',
        date: '2024-05-28',
        createdBy: 'John Doe',
        status: 'PUBLISHED',
        country: 'Nigeria',
        link: 'https://example.com/customer',
        endpoints: ['Phone Number', 'BVN', 'NIN', 'CAC', 'International Passport'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Product API Template',
        date: '2024-05-28',
        createdBy: 'Alice Smith',
        status: 'DRAFTS',
        country: 'USA',
        link: 'https://example.com/product',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Order API Template',
        date: '2024-05-28',
        createdBy: 'Jane Doe',
        status: 'PUBLISHED',
        country: 'UK',
        link: 'https://example.com/order',
        endpoints: ['Phone Number', 'NIN'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Inventory API Template',
        date: '2024-05-28',
        createdBy: 'Bob Johnson',
        status: 'DRAFTS',
        country: 'Canada',
        link: 'https://example.com/inventory',
        endpoints: ['Phone Number', 'BVN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Billing API Template',
        date: '2024-05-28',
        createdBy: 'Ella Wilson',
        status: 'PUBLISHED',
        country: 'Australia',
        link: 'https://example.com/billing',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYC',
    },

    {
        name: 'GTB Customer API Template',
        date: '2024-05-28',
        createdBy: 'John Doe',
        status: 'PUBLISHED',
        country: 'Nigeria',
        link: 'https://example.com/customer',
        endpoints: ['Phone Number', 'BVN', 'NIN', 'CAC', 'International Passport'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Product API Template',
        date: '2024-05-28',
        createdBy: 'Alice Smith',
        status: 'DRAFTS',
        country: 'USA',
        link: 'https://example.com/product',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Order API Template',
        date: '2024-05-28',
        createdBy: 'Jane Doe',
        status: 'PUBLISHED',
        country: 'UK',
        link: 'https://example.com/order',
        endpoints: ['Phone Number', 'NIN'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Inventory API Template',
        date: '2024-05-28',
        createdBy: 'Bob Johnson',
        status: 'DRAFTS',
        country: 'Canada',
        link: 'https://example.com/inventory',
        endpoints: ['Phone Number', 'BVN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Billing API Template',
        date: '2024-05-28',
        createdBy: 'Ella Wilson',
        status: 'PUBLISHED',
        country: 'Australia',
        link: 'https://example.com/billing',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYC',
    },
]

export interface RecentAPIs {
    name: string
    date: string
    createdBy: string
    status: 'PUBLISHED' | 'DRAFTS'
    country: string
    link: string
    endpoints: string[]
    inquiryType: 'KYC' | 'KYB'
}

export const templates: RecentAPIs[] = [
    {
        name: 'GTB Customer API Template',
        date: '2024-05-28',
        createdBy: 'John Doe',
        status: 'PUBLISHED',
        country: 'Nigeria',
        link: 'https://example.com/customer',
        endpoints: ['Phone Number', 'BVN', 'NIN', 'CAC', 'International Passport'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Product API Template',
        date: '2024-05-28',
        createdBy: 'Alice Smith',
        status: 'DRAFTS',
        country: 'USA',
        link: 'https://example.com/product',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Order API Template',
        date: '2024-05-28',
        createdBy: 'Jane Doe',
        status: 'PUBLISHED',
        country: 'UK',
        link: 'https://example.com/order',
        endpoints: ['Phone Number', 'NIN'],
        inquiryType: 'KYC',
    },
    {
        name: 'GTB Inventory API Template',
        date: '2024-05-28',
        createdBy: 'Bob Johnson',
        status: 'DRAFTS',
        country: 'Canada',
        link: 'https://example.com/inventory',
        endpoints: ['Phone Number', 'BVN'],
        inquiryType: 'KYB',
    },
    {
        name: 'GTB Billing API Template',
        date: '2024-05-28',
        createdBy: 'Ella Wilson',
        status: 'PUBLISHED',
        country: 'Australia',
        link: 'https://example.com/billing',
        endpoints: ['Phone Number', 'BVN', 'NIN'],
        inquiryType: 'KYC',
    },
]
