import { actionTypes } from '../../constants/actionTypes'
import { 
    ReportAnalysisFailure,
    ReportAnalysisFailurePayload,
    ReportAnalysisHistoryFailure,
    ReportAnalysisHistoryFailurePayload,
    ReportAnalysisHistoryPayload,
    ReportAnalysisHistoryRequest,
    ReportAnalysisHistorySuccess,
    ReportAnalysisHistorySuccessPayload,
    ReportAnalysisPayload,
    ReportAnalysisRequest,
    ReportAnalysisSuccess,
    ReportAnalysisSuccessPayload,
} from './types';


export const reportAnalysisRequest = (payload:ReportAnalysisPayload):ReportAnalysisRequest => ({
    type: actionTypes.REPORT_ANALYSIS_REQUEST,
    payload,
});

export const reportAnalysisSuccess = (payload:ReportAnalysisSuccessPayload):ReportAnalysisSuccess => ({
    type: actionTypes.REPORT_ANALYSIS_SUCCESS,
    payload,
});

export const reportAnalysisFailure = (payload:ReportAnalysisFailurePayload):ReportAnalysisFailure => ({
    type: actionTypes.REPORT_ANALYSIS_FAILURE,
    payload,
});




export const reportAnalysisHistoryRequest = (payload:ReportAnalysisHistoryPayload):ReportAnalysisHistoryRequest => ({
    type: actionTypes.REPORT_ANALYSIS_HISTORY_REQUEST,
    payload,
});

export const reportAnalysisHistorySuccess = (payload:ReportAnalysisHistorySuccessPayload):ReportAnalysisHistorySuccess => ({
    type: actionTypes.REPORT_ANALYSIS_HISTORY_SUCCESS,
    payload,
});

export const reportAnalysisHistoryFailure = (payload:ReportAnalysisHistoryFailurePayload):ReportAnalysisHistoryFailure => ({
    type: actionTypes.REPORT_ANALYSIS_HISTORY_FAILURE,
    payload,
});

