import React from 'react'
import WebhookComp from '../../components/sdkLibraries/webhook'
import SDKConfigComp from '../../components/sdkLibraries/webhook/config'
import SDKListsComp from '../../components/sdkLibraries/webhook/Lists'

export default function SDKLibraryPage() {
  return (
    <div
     style={{backgroundColor:'#fcfcfc'}}
    >
      <SDKListsComp />
      {/* <SDKConfigComp/> */}
      {/* <WebhookComp/> */}
    </div>
  )
}
