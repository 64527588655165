import { useEffect, useState } from 'react'
import { RecentAPIs } from '../../data'

interface EditModalProps {
    isOpen: boolean
    apiDetails: RecentAPIs | null
    onClose: () => void
    onSave: (updatedApiDetails: RecentAPIs) => void
}

export const EditModal: React.FC<EditModalProps> = ({ isOpen, apiDetails, onClose, onSave }) => {
    const [details, setDetails] = useState<any>(apiDetails)

    useEffect(() => {
        setDetails(apiDetails)
    }, [apiDetails])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    if (!isOpen || !details) return null

    return (
        // <div className="modal">
        //     <div className="modal-content">
        //         <span className="close" onClick={onClose}>
        //             &times;
        //         </span>
        //         <h2>Edit API</h2>
        //         <input type="text" name="name" value={details.name} onChange={handleChange} />
        //         <input
        //             type="text"
        //             name="createdBy"
        //             value={details.createdBy}
        //             onChange={handleChange}
        //         />

        //         <button onClick={() => onSave(details)}>Save Changes</button>
        //     </div>
        // </div>

        <div className="modal">
            <div className="modal-content">
                <div className="close-con">
                    <span className="close" onClick={onClose}>
                        &times;
                    </span>
                </div>
                <div className="underline"></div>
                <h2>Rename API</h2>
                <input type="text" name="name" value={details.name} onChange={handleChange} />
                <button
                    className="btn btn-light-green w-100 py-3 create-api mb-3"
                    onClick={() => onSave(details)}>
                    Update
                </button>
                <button className="btn btn-grey" onClick={onClose}>
                    No, Go back
                </button>
            </div>
        </div>
    )
}
