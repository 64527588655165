import './styles/styles.css'
import copy from '../../../src/assets/document-copy.png'
import { Link } from 'react-router-dom'
import { templateCollection, templates as defaultTemplates } from '../../data'
import { RecentAPIs } from '../../data'
import { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast'
import { Button, Form } from 'react-bootstrap'
import star from '../../assets/star.png'
import codee from '../../assets/code.png'
import send from '../../../src/assets/send.png'
import calender from '../../assets/calendar.png'
import profile from '../../assets/profile-circle.png'
import { useLocation } from 'react-router-dom'
import { FaLink } from 'react-icons/fa'
import Barrow from '../../assets/arrow-left.png'

const formatDate = (date: string): string => {
    return date
}

export default function ViewUserAPI({ templates: initialTemplates = defaultTemplates }) {
    const location = useLocation()
    const apiDetails = location.state as RecentAPIs
    const [code, setCode] = useState<string>('')
    const firstLetter = apiDetails.name.charAt(0)

    const [linkCopied, setLinkCopied] = useState(false)

    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(apiDetails.link)
            .then(() => {
                setLinkCopied(true)
                toast.success('Link copied to clipboard!')
                setTimeout(() => setLinkCopied(false), 3000) // Reset after 3 seconds
            })
            .catch((err) => {
                toast.error('Failed to copy link')
                console.error('Failed to copy link: ', err)
            })
    }

    return (
        <div className="dashboard-area vh-100 mb-4">
            <Toaster />
            <div className="container-fluid h-100 px-md-4 my-4">
                <div className="row h-100">
                    <div className="col-md-8 d-flex flex-column">
                        <Link
                            to="/apibuilder"
                            style={{
                                lineHeight: '20px',
                                textDecoration: 'none',
                                background: '#F4F4F4',
                                padding: '15px',
                                borderRadius: '8px',
                                color: '#1D1D1D',
                                fontSize: '14px',
                                fontWeight: '500',
                                width: 'fit-content',
                                marginBottom: '20px',
                            }}>
                            <img src={Barrow} alt="Go Back" style={{ marginRight: '5px' }} />
                            Go Back
                        </Link>
                        <div className="card mb-4">
                            <div className="card-body user-details">
                                <div
                                    style={{
                                        backgroundColor: '#DAF1EE',
                                        color: '#0B0A0A',
                                        borderRadius: '50%',
                                        width: '70px',
                                        height: '70px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '30px',
                                        marginRight: '10px',
                                        lineHeight: '32px',
                                        fontWeight: '700',
                                    }}>
                                    {firstLetter}
                                </div>
                                <div>
                                    <h3 className="template-text">{apiDetails.name}</h3>
                                    <div className="date-user">
                                        <p className="sub-text">
                                            <span>
                                                <img src={calender} alt="Calendar Icon" /> Date
                                                Published:
                                            </span>
                                            {formatDate(apiDetails.date)}
                                        </p>
                                        <span className="seperator"></span>
                                        <p className="sub-text">
                                            <span>
                                                <img src={profile} alt="Profile Icon" /> Creator:
                                            </span>
                                            {apiDetails.createdBy}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body user-details">
                                <div>
                                    <h3 className="template-text">Copy API link</h3>
                                    <div className="date-user copy-link">
                                        <p
                                            className="sub-text"
                                            onClick={handleCopyLink}
                                            style={{ cursor: 'pointer' }}>
                                            <span>
                                                <FaLink className="menu-icon" />
                                                {linkCopied ? 'Copied' : 'Copy Link'}
                                            </span>
                                            {apiDetails.link}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="response-structure card-body">
                                <div className="resStru">
                                    <h5>RESPONSE STRUCTURE</h5>
                                </div>

                                <div
                                    style={{
                                        backgroundColor: '#333',
                                        color: '#fff',
                                        padding: '20px',
                                        borderRadius: '16px',
                                        position: 'relative',
                                        height: '500px',
                                        marginTop: '10px',
                                        background: '#0b0a0a',
                                    }}>
                                    <code style={{ whiteSpace: 'pre-wrap' }}>
                                        {code || '//Your API ;-)'}
                                    </code>
                                    {!code && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                color: 'white',
                                                textAlign: 'center',
                                                pointerEvents: 'none',
                                            }}>
                                            <img src={codee} alt="codee" />
                                            <br />
                                            Your code will display here
                                            <br />
                                            When you have generated your API, it will appear here
                                        </div>
                                    )}
                                    <Button
                                        onClick={() => navigator.clipboard.writeText(code)}
                                        variant="link"
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                        }}>
                                        <span
                                            className="copy-icon"
                                            style={{
                                                gap: '10px',
                                                color: 'white',
                                                background: '#1d1d1d',
                                                borderRadius: '16px',
                                                padding: '4px 10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            copy{' '}
                                            <img
                                                src={copy}
                                                alt="copy icon"
                                                style={{
                                                    marginLeft: '3px',
                                                    filter: 'invert(1)',
                                                }}
                                            />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="col-md-4 mb-5">
                        <div
                            className="card w-100"
                            style={{ borderRadius: '10px', minHeight: '100px' }}>
                            <div className="card-body">
                                <div
                                    className="header-style"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    <h2>Endpoints</h2>
                                    <span className="sub-text resStru">
                                        {apiDetails.endpoints.length}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                    }}>
                                    {apiDetails.endpoints.map((endpoint, index) => (
                                        <p key={index} className="sub-text resStru">
                                            {endpoint}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div
                            className="card w-100 mt-4 mb-4"
                            style={{
                                borderRadius: '10px',
                                minHeight: '100px',
                            }}>
                            <div className="card-body">
                                <h2 className="header-style">Available Country</h2>

                                <div className="resStru">
                                    <p className="sub-text">{apiDetails.country}</p>
                                </div>
                            </div>
                        </div>

                        <div className="card w-100 cont">
                            <div className="card-body">
                                <a
                                    href="/createapi"
                                    style={{
                                        lineHeight: '20px',
                                        textDecoration: 'none',
                                        background: '#F4F4F4',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        color: '#1D1D1D',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        width: '156px',
                                    }}>
                                    <span
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            display: 'inline-block',
                                            marginRight: '8px',
                                        }}>
                                        <img src={star} />
                                    </span>
                                    IMPROVE WITH AI
                                </a>

                                <h2 className="header-style mt-4">
                                    Use our AI engine to modify or create a new API
                                </h2>
                                <p className="sub-text">
                                    Try our our API engine to create a handy API for your needs
                                </p>

                                <div className="mt-3">
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <input
                                            type="text"
                                            placeholder="Enter your prompt"
                                            style={{
                                                width: '100%',
                                                padding: '10px 40px 10px 10px',
                                                borderRadius: '10px',
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '40%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                width: '24px',
                                                height: '24px',
                                                display: 'inline-block',
                                                textAlign: 'center',
                                                lineHeight: '20px',
                                                cursor: 'pointer',
                                            }}>
                                            <img src={send} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
