import React, { useEffect, useState } from 'react'
import NotificationToast from '../../../../../components/utils/notifToast'
import { backgroundCheckRequestAnswerUploadRequest, backgroundCheckRequestGetCandidateFormRequest } from '../../../../../redux/actions/products/backgroundCheck/request/checks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/reducers'
import CheckSummaryComp from '../../../../../components/products/backgroundCheck/request/checks/checkSummary'
import { Player } from '@lottiefiles/react-lottie-player'
import successVerifGif from '../../../../../assets/successVerif.json'
import Mainloader from '../../../../../components/utils'

export default function BackgroundCheckRequestCandidateFormPage() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState("")
    const [notifTitle, setNotifTitle] = useState("")
    const [allForm, setAllForm] = useState<any>([]);
    const [formPage, setFormPage] = useState(1)
    const [currentStep, setCurrentStep] = useState(0);
    const [currentForm, setCurrentForm] = useState<any>({});

    const [payloadData, setPayloadData] = useState({})
    const [payloadDataArr, setPayloadDataArr] = useState<any[]>([])

    const [successModal, setSuccessModal] = useState(false)


    const candidateFormState = useSelector((state: RootState) => state.backgroundCheckRequestGetCandidateFormReducer)

    const queryParams = new URLSearchParams(window.location.search)
    let requestId = queryParams.get("ref") || ""

    const dispatch = useDispatch()

    useEffect(() => {
        getCandidateForm()
    }, [])

    useEffect(() => {
        getFormAt(currentStep)
    }, [currentStep, candidateFormState?.resp?.detail])


    let getCandidateForm = () => {
        const callback = (data: any) => {

            if (data.status) {
                if (data?.detail?.request_completed) {
                    setFormPage(3)
                }
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                form_id: requestId,
            },
            callback,
        };
        dispatch(backgroundCheckRequestGetCandidateFormRequest(data))
    }



    let getFormAt = (index: number) => {
        if (!candidateFormState?.isLoading && candidateFormState?.resp?.detail) {
            setAllForm(Object?.entries(candidateFormState?.resp?.detail?.form)?.reverse())
            setCurrentForm(Object?.entries(candidateFormState?.resp?.detail?.form)?.reverse()[index])
        }
    }


    let triggerKeyUpdate = (title: any, key: any, val: any, id: any, count: any) => {

        let keyDataArr: any = payloadDataArr

        let keyDataIndex = payloadDataArr.findIndex(item => ((item?.form_value === key) && (item?.title === title) && (item?.form_id === (id + count))))


        if (keyDataIndex !== -1) {
            keyDataArr.splice(keyDataIndex, 1)
            keyDataArr.push({ "title": title, "form_value": key, "form_data": val, "form_id": id + count })
        }
        else {
            keyDataArr.push({ "title": title, "form_value": key, "form_data": val, "form_id": id + count })
        }

        // setPayloadData(keyData)
        setPayloadDataArr(keyDataArr)
        reformatData()
    }


    let reformatData = () => {
        const correctData: any = {};
        payloadDataArr?.forEach((obj) => {
            correctData[obj.title] = payloadDataArr?.filter((item: any) => item?.title === obj?.title)
        });

        setPayloadData({ ...correctData })
    }

    // let verifyData = () => {
    //     if (payloadDataArr?.length < 1 || payloadDataArr?.some((val: any) => val.value === "")) {
    //         setNotifTitle('Error')
    //         setNotif('Please fill in all required fields')
    //         setNotifVal(true)
    //         return
    //     }
    //     reformatData()
    //     setCurrentStep(currentStep + 1)

    // }

    let nextForm = (formName: any) => {
        if (payloadDataArr?.length < 1 || payloadDataArr?.some((val: any) => val?.form_data === "") ||
            (currentForm[1]?.length !== (payloadDataArr.filter((val: any) => val?.title === currentForm[0])?.length))
        ) {
            setNotifTitle('Error')
            setNotif('Please fill in all fields')
            setNotifVal(true)
            return
        }
        else if (formName === allForm[allForm?.length - 1][0]) {
            reformatData()
            goToFormPage(2)
        }
        else {
            reformatData()
            setCurrentStep(currentStep + 1)
            // verifyData()
        }
    }


    let submitForm = () => {
        const callback = (data: any) => {
            if (data?.status) {
                setNotifTitle("Success")
                setNotif("Response successfully sent")
                setNotifVal(true)

                setTimeout(() => {
                    setFormPage(3)
                }, 1000);
                // toggleSuccessModal()
            }
            else {
                setNotifTitle("Error")
                setNotif(data.detail)
                setNotifVal(true)
            }
        };
        let data: any = {
            values: {
                request_id: requestId,
                ids: payloadData
            },
            callback,
        };
        dispatch(backgroundCheckRequestAnswerUploadRequest(data))
    }

    let goToFormPage = (val: any) => {
        setFormPage(val)
    }

    let getValueForKey = (title: string, key: string, id: any) => {
        // console.log(payloadDataArr)
        const mainValue = payloadDataArr?.find((val: any) => (val.title === title) && (val.form_value === key) && (val.form_id === id));
        // console.log(mainValue)

        return mainValue?.form_data;
        
        // if (mainValue) {
        //     return mainValue.form_data;
        // } else {
        //     return `Enter ${key?.replaceAll("_", " ")}`;
        // }
    }

    let toggleSuccessModal = () => {
        setSuccessModal(!successModal)
    }

    // console.log(payloadData)

    return (
        <div>

            {(notif && notifVal) && <NotificationToast title={notifTitle} message={notif} closeNotif={() => setNotifVal(!notifVal)} />}

            <div className="text-white py-4 mb-5" style={{ background: "#003E51" }}>
                <div className="container">
                    <h5>Complete A Background Check</h5>
                </div>
            </div>

            {candidateFormState?.isLoading && <Mainloader />}

            {formPage === 1 &&
                <>
                    {!candidateFormState?.isLoading &&
                        <div className="container">
                            <div>
                                <small> step {currentStep + 1} of {allForm?.length}</small>
                                <h1> {currentForm[0]?.charAt(0)?.toUpperCase() + currentForm[0]?.substring(1).replaceAll("_", " ")}</h1>
                                <p>Please enter your {currentForm[0]?.replaceAll("_", " ")} details</p>
                            </div>
                            <div className='row justify-content-between '>
                                {allForm?.length > 0 && (
                                    <div className="col-md-5 mt-5">
                                        <div className=''>
                                            {currentForm && currentForm[1]?.map((form: any, i: number) => {
                                                return (
                                                    <div className="mt-1" key={i}>
                                                        <label htmlFor="firstname">{form?.form_label}</label>
                                                        {/* <input type={form?.form_type} defaultValue={getValueForKey(currentForm[0], form?.form_value, form.form_id + i)}  */}
                                                        <input type={form?.form_type}
                                                            name={form?.form_value}
                                                            value={getValueForKey(currentForm[0], form?.form_value, form.form_id + i) || ""}
                                                            className='form-control' placeholder={form?.form_label}
                                                            onChange={e => triggerKeyUpdate(currentForm[0], form?.form_value, e?.target?.value, form?.form_id, i)}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                )
                                            }
                                            )}

                                            <button className='btn btn-deep-green mt-3 px-5 mt-5 w-100'
                                                onClick={() => nextForm(currentForm[0])}
                                            >
                                                Next
                                            </button>

                                        </div>
                                    </div>
                                )}

                                <div className="col-md-4 mt-5">
                                    <div className="card mt-4 pb-3">
                                        <div className="card-body">
                                            {candidateFormState?.resp?.detail && Object?.keys(candidateFormState?.resp?.detail?.form)?.reverse()?.map((val: any, i: number) => (
                                                <div key={i} className='d-flex align-items-center mt-3'>
                                                    {currentStep > i ?
                                                        <i className="ri-checkbox-circle-fill ri-xl me-2" />
                                                        :
                                                        <i className="ri-checkbox-blank-circle-line ri-xl me-2" />
                                                    }
                                                    <p className='p-0 m-0' style={{ cursor: "pointer" }} onClick={(currentStep > i) ?() => setCurrentStep(i): ()=>{}}>
                                                        {val[0]?.toUpperCase() + val?.replaceAll("_", " ")?.slice(1)}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

            {formPage === 2 && <CheckSummaryComp submitForm={submitForm} goToFormPage={goToFormPage} collectedData={payloadData} successModal={successModal} toggleSuccessModal={toggleSuccessModal} />}

            {formPage === 3 &&
                <div>
                    <div className="col-md-6 col-lg-4 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="main-modal-body text-center">
                                    <div className="col-md-6 mx-auto mt-4">
                                        <Player
                                            src={successVerifGif}
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </div>
                                    <div className="my-5">
                                        <h4>Thank You</h4>
                                        <p className='my-3'>You have Succesfully Submitted this form</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
