import { actionTypes } from '../../constants/actionTypes'
import { 
    AllPremblyProductsFailure,
    AllPremblyProductsFailurePayload,
    AllPremblyProductsPayload,
    AllPremblyProductsRequest,
    AllPremblyProductsSuccess,
    AllPremblyProductsSuccessPayload,
} from './types';


export const allPremblyProductsRequest = (payload:AllPremblyProductsPayload):AllPremblyProductsRequest => ({
    type: actionTypes.ALL_PREMBLY_PRODUCTS_REQUEST,
    payload,
});

export const allPremblyProductsSuccess = (payload:AllPremblyProductsSuccessPayload):AllPremblyProductsSuccess => ({
    type: actionTypes.ALL_PREMBLY_PRODUCTS_SUCCESS,
    payload,
});

export const allPremblyProductsFailure = (payload:AllPremblyProductsFailurePayload):AllPremblyProductsFailure => ({
    type: actionTypes.ALL_PREMBLY_PRODUCTS_FAILURE,
    payload,
});
