import { SdkBiometricsInfoState, SdkCreateState, SdkDeleteSingleState, SdkEndpointsInfoState, SdkInfoAllState, SdkInfoSingleState, SdkReportInfoState, SdkReportSingleInfoState, SdkUpdateState, UpdateWebhookUrlState } from '../../actions/sdkLibraries/types';
import { actionTypes } from './../../constants/actionTypes';

const initialState: UpdateWebhookUrlState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkEndpointsInitialState: SdkEndpointsInfoState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkCreateInitialState: SdkCreateState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkInfoAllInitialState: SdkInfoAllState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkUpdateInitialState: SdkUpdateState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkInfoSingleInitialState: SdkInfoSingleState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkBioInitialState: SdkBiometricsInfoState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkReportInitialState: SdkReportInfoState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkReportSingleInitialState: SdkReportSingleInfoState = {
    isLoading: false,
    error: null,
    resp: null,

};
const sdkDeleteSingleInitialState: SdkDeleteSingleState = {
    isLoading: false,
    error: null,
    resp: null,

};

export const updateWebhookUrlReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.UPDATE_WEBHOOK_URL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.UPDATE_WEBHOOK_URL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.UPDATE_WEBHOOK_URL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkEndpointsInfoReducer = (state = sdkEndpointsInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_ENDPOINTS_INFO_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_ENDPOINTS_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_ENDPOINTS_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkCreateReducer = (state = sdkCreateInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_CREATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkInfoAllReducer = (state = sdkInfoAllInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_INFO_ALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_INFO_ALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_INFO_ALL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkUpdateReducer = (state = sdkUpdateInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkInfoSingleReducer = (state = sdkInfoSingleInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_INFO_SINGLE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_INFO_SINGLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_INFO_SINGLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkBiometricsInfoReducer = (state = sdkBioInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_BIOMETRICS_INFO_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_BIOMETRICS_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_BIOMETRICS_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkReportInfoReducer = (state = sdkReportInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_REPORT_INFO_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_REPORT_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_REPORT_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkDeleteSingleReducer = (state = sdkDeleteSingleInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_DELETE_SINGLE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_DELETE_SINGLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_DELETE_SINGLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export const sdkReportSingleInfoReducer = (state = sdkReportSingleInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.SDK_REPORT_SINGLE_INFO_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.SDK_REPORT_SINGLE_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp: action.payload.resp
            };
        case actionTypes.SDK_REPORT_SINGLE_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};