import { actionTypes } from '../../constants/actionTypes'
import { 
    SdkBiometricsInfoFailure, SdkBiometricsInfoFailurePayload, SdkBiometricsInfoPayload, SdkBiometricsInfoRequest,
    SdkBiometricsInfoSuccess, SdkBiometricsInfoSuccessPayload,

    SdkCreateFailure, SdkCreateFailurePayload, SdkCreatePayload, 
    SdkCreateRequest, SdkCreateSuccess, SdkCreateSuccessPayload, 
    
    SdkDeleteSingleFailure, 
    
    SdkDeleteSingleFailurePayload, 
    
    SdkDeleteSinglePayload, 
    
    SdkDeleteSingleRequest, 
    
    SdkDeleteSingleSuccess, 
    
    SdkDeleteSingleSuccessPayload, 
    
    SdkEndpointsInfoFailure, SdkEndpointsInfoFailurePayload, SdkEndpointsInfoPayload,
    SdkEndpointsInfoRequest, SdkEndpointsInfoSuccess, SdkEndpointsInfoSuccessPayload,

    SdkInfoAllFailure, SdkInfoAllFailurePayload, SdkInfoAllPayload,
    SdkInfoAllRequest, SdkInfoAllSuccess, SdkInfoAllSuccessPayload,

    SdkInfoSingleFailure, SdkInfoSingleFailurePayload, SdkInfoSinglePayload,
    SdkInfoSingleRequest, SdkInfoSingleSuccess, SdkInfoSingleSuccessPayload,

    SdkReportInfoFailure, SdkReportInfoFailurePayload, SdkReportInfoPayload,
    SdkReportInfoRequest, SdkReportInfoSuccess, SdkReportInfoSuccessPayload,

    SdkReportSingleInfoFailure,

    SdkReportSingleInfoFailurePayload,

    SdkReportSingleInfoPayload,

    SdkReportSingleInfoRequest,

    SdkReportSingleInfoSuccess,

    SdkReportSingleInfoSuccessPayload,

    SdkUpdateFailure, SdkUpdateFailurePayload, SdkUpdatePayload,
    SdkUpdateRequest, SdkUpdateSuccess, SdkUpdateSuccessPayload,

    UpdateWebhookUrlFailure, UpdateWebhookUrlFailurePayload, UpdateWebhookUrlPayload, 
    UpdateWebhookUrlRequest, UpdateWebhookUrlSuccess, UpdateWebhookUrlSuccessPayload 
} from './types';

export const updateWebhookUrlRequest = (payload:UpdateWebhookUrlPayload):UpdateWebhookUrlRequest => ({
    type: actionTypes.UPDATE_WEBHOOK_URL_REQUEST,
    payload,
});

export const updateWebhookUrlSuccess = (payload:UpdateWebhookUrlSuccessPayload):UpdateWebhookUrlSuccess => ({
    type: actionTypes.UPDATE_WEBHOOK_URL_SUCCESS,
    payload,
});

export const updateWebhookUrlFailure = (payload:UpdateWebhookUrlFailurePayload):UpdateWebhookUrlFailure => ({
    type: actionTypes.UPDATE_WEBHOOK_URL_FAILURE,
    payload,
});



export const sdkEndpointsInfoRequest = (payload:SdkEndpointsInfoPayload):SdkEndpointsInfoRequest => ({
    type: actionTypes.SDK_ENDPOINTS_INFO_REQUEST,
    payload,
});

export const sdkEndpointsInfoSuccess = (payload:SdkEndpointsInfoSuccessPayload):SdkEndpointsInfoSuccess => ({
    type: actionTypes.SDK_ENDPOINTS_INFO_SUCCESS,
    payload,
});

export const sdkEndpointsInfoFailure = (payload:SdkEndpointsInfoFailurePayload):SdkEndpointsInfoFailure => ({
    type: actionTypes.SDK_ENDPOINTS_INFO_FAILURE,
    payload,
});



export const sdkCreateRequest = (payload:SdkCreatePayload):SdkCreateRequest => ({
    type: actionTypes.SDK_CREATE_REQUEST,
    payload,
});

export const sdkCreateSuccess = (payload:SdkCreateSuccessPayload):SdkCreateSuccess => ({
    type: actionTypes.SDK_CREATE_SUCCESS,
    payload,
});

export const sdkCreateFailure = (payload:SdkCreateFailurePayload):SdkCreateFailure => ({
    type: actionTypes.SDK_CREATE_FAILURE,
    payload,
});



export const sdkInfoAllRequest = (payload:SdkInfoAllPayload):SdkInfoAllRequest => ({
    type: actionTypes.SDK_INFO_ALL_REQUEST,
    payload,
});

export const sdkInfoAllSuccess = (payload:SdkInfoAllSuccessPayload):SdkInfoAllSuccess => ({
    type: actionTypes.SDK_INFO_ALL_SUCCESS,
    payload,
});

export const sdkInfoAllFailure = (payload:SdkInfoAllFailurePayload):SdkInfoAllFailure => ({
    type: actionTypes.SDK_INFO_ALL_FAILURE,
    payload,
});



export const sdkUpdateRequest = (payload:SdkUpdatePayload):SdkUpdateRequest => ({
    type: actionTypes.SDK_UPDATE_REQUEST,
    payload,
});

export const sdkUpdateSuccess = (payload:SdkUpdateSuccessPayload):SdkUpdateSuccess => ({
    type: actionTypes.SDK_UPDATE_SUCCESS,
    payload,
});

export const sdkUpdateFailure = (payload:SdkUpdateFailurePayload):SdkUpdateFailure => ({
    type: actionTypes.SDK_UPDATE_FAILURE,
    payload,
});



export const sdkInfoSingleRequest = (payload:SdkInfoSinglePayload):SdkInfoSingleRequest => ({
    type: actionTypes.SDK_INFO_SINGLE_REQUEST,
    payload,
});

export const sdkInfoSingleSuccess = (payload:SdkInfoSingleSuccessPayload):SdkInfoSingleSuccess => ({
    type: actionTypes.SDK_INFO_SINGLE_SUCCESS,
    payload,
});

export const sdkInfoSingleFailure = (payload:SdkInfoSingleFailurePayload):SdkInfoSingleFailure => ({
    type: actionTypes.SDK_INFO_SINGLE_FAILURE,
    payload,
});



export const sdkBiometricsInfoRequest = (payload:SdkBiometricsInfoPayload):SdkBiometricsInfoRequest => ({
    type: actionTypes.SDK_BIOMETRICS_INFO_REQUEST,
    payload,
});

export const sdkBiometricsInfoSuccess = (payload:SdkBiometricsInfoSuccessPayload):SdkBiometricsInfoSuccess => ({
    type: actionTypes.SDK_BIOMETRICS_INFO_SUCCESS,
    payload,
});

export const sdkBiometricsInfoFailure = (payload:SdkBiometricsInfoFailurePayload):SdkBiometricsInfoFailure => ({
    type: actionTypes.SDK_BIOMETRICS_INFO_FAILURE,
    payload,
});



export const sdkReportInfoRequest = (payload:SdkReportInfoPayload):SdkReportInfoRequest => ({
    type: actionTypes.SDK_REPORT_INFO_REQUEST,
    payload,
});

export const sdkReportInfoSuccess = (payload:SdkReportInfoSuccessPayload):SdkReportInfoSuccess => ({
    type: actionTypes.SDK_REPORT_INFO_SUCCESS,
    payload,
});

export const sdkReportInfoFailure = (payload:SdkReportInfoFailurePayload):SdkReportInfoFailure => ({
    type: actionTypes.SDK_REPORT_INFO_FAILURE,
    payload,
});



export const sdkReportSingleInfoRequest = (payload:SdkReportSingleInfoPayload):SdkReportSingleInfoRequest => ({
    type: actionTypes.SDK_REPORT_SINGLE_INFO_REQUEST,
    payload,
});

export const sdkReportSingleInfoSuccess = (payload:SdkReportSingleInfoSuccessPayload):SdkReportSingleInfoSuccess => ({
    type: actionTypes.SDK_REPORT_SINGLE_INFO_SUCCESS,
    payload,
});

export const sdkReportSingleInfoFailure = (payload:SdkReportSingleInfoFailurePayload):SdkReportSingleInfoFailure => ({
    type: actionTypes.SDK_REPORT_SINGLE_INFO_FAILURE,
    payload,
});



export const sdkDeleteSingleRequest = (payload:SdkDeleteSinglePayload):SdkDeleteSingleRequest => ({
    type: actionTypes.SDK_DELETE_SINGLE_REQUEST,
    payload,
});

export const sdkDeleteSingleSuccess = (payload:SdkDeleteSingleSuccessPayload):SdkDeleteSingleSuccess => ({
    type: actionTypes.SDK_DELETE_SINGLE_SUCCESS,
    payload,
});

export const sdkDeleteSingleFailure = (payload:SdkDeleteSingleFailurePayload):SdkDeleteSingleFailure => ({
    type: actionTypes.SDK_DELETE_SINGLE_FAILURE,
    payload,
});