import React from 'react'
// import LoadingPageLogo from '../../assets/loadingPageLogo.svg'
// import loadingPageGif from '../../assets/loadingPageGif.gif'
import { Spinner } from 'react-bootstrap'

export default function LoadingPage() {
  return (
    <div className='loading-page-area'>
      <div>
        <div style={{ width: "200px", position: "relative", bottom: "-50px" }}>
          <div>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="spinner-border" style={{width: "3rem", height: "3rem"}}
            />
            <div className="sr-only mt-3">
              Loading...
            </div>
          </div>
          {/* <img src={LoadingPageLogo} alt="" className='w-100' /> */}
        </div>
        {/* <div style={{width:"200px"}}>
            <img src={loadingPageGif} alt="" className='w-100' />
          </div> */}
      </div>
    </div>
  )
}
