import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import NotificationToast from '../../utils/notifToast'
import LoadingPage from '../../utils/loadingPage'
import global from '../../../redux/constants/global'
import { authorizationRedirect } from '../../../redux/constants/api'
import Cookies from 'js-cookie'
import { tenantInfoRequest } from '../../../redux/actions/accessToken'

export default function TenantWrapper(props:any) {
    const [tokenLoading, setTokenLoading] = useState(true)
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')


    const dispatch = useDispatch();


    // const queryParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        if(tokenLoading === true){
            getTenantInfo()
        }
    }, []);

    let getTenantInfo = () => {
        setTokenLoading(true)
        const callback = (data: any) => {
            if (data?.status) {
                // Cookies.set('babtbu', data?.data?.token, { secure: true })
                // Cookies.set("org", data?.data?.organisation[0]?.organisation?.id, { secure: true })

                Cookies.set('btbibd', data?.tenant?.id, { secure: true })
                setTokenLoading(false)
                // window.location.href = window.location.pathname
            } else {
                setTokenLoading(false)
                setNotifTitle("Error")
                setNotif(data?.detail)
                setNotifVal(true)
                // window.location.href = global.appBaseUrl + "login"
            }
        }
        let data = {
            values: {
                host: window.location.hostname?.includes('localhost') ? "dev.prembly.com" : window.location.hostname,
                // host: "account.prembly.com",
            },
            callback,
        }
        dispatch(tenantInfoRequest(data))
    }

    if (tokenLoading === true) {
        return (
            <div>
                <LoadingPage />
            </div>
        )
    }
    else {
        return (
            <div>
                {notif && notifVal && (
                    <NotificationToast
                        title={notifTitle}
                        message={notif}
                        closeNotif={() => setNotifVal(!notifVal)}
                    />
                )}
                {props?.children}
            </div>
        )

    }
}
