import React from 'react'
import './modal.css'
import { classnames } from '../../utils'
import { CancelIncon } from '../../assets'
// import { ModalCancelIcon } from '../../assets'

const Modal = ({
    open,
    setOpen,
    children,
    modalCardClasses,
    modalTitle,
    showCancel = true,
    modalBoxstyle={},
}: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode
    modalCardClasses?: string
    modalTitle: string
    showCancel?: boolean,
    modalBoxstyle?:React.CSSProperties
}) => {
    const handleCloseModal = () => {
        setOpen(false)
    }
    return (
        <div>
            {open?
            <div className="main-modal d-flex justify-content-center align-items-center">
            <div className={`modal-inner-card ${classnames(modalCardClasses)}`}
            style={modalBoxstyle}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="modal-title">{modalTitle}</h6>
                    {showCancel && (
                        <CancelIncon onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
                    )}
                </div>
                <div className="mt-3">{children}</div>
            </div>
        </div>:''
        }
        </div>

    )
}

export default Modal
