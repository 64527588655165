import './styles/styles.css'
import worldIcon from '../../../src/assets/world-icon.png'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
// import { useFormik } from 'formik'
import * as Yup from 'yup'
import copy from '../../assets/copy.png'
import codee from '../../assets/code.png'
import deletee from '../../assets/trash.png'
import userIcon from '../../assets/user-square.png'
import icon from '../../assets/Icon.png'
import close from '../../assets/add.png'
import Barrow from '../../assets/arrow-left.png'
import { Form, Button, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import NotificationToast from '../utils/notifToast'
import InputWithLabel from '../form/CustomhookFormFields/InputWithLabel'
import SelectWithLabel from '../form/CustomhookFormFields/SelectWithLabel'
import axios from 'axios'
// getApiBuilderPremblyEndponts
import { createApiBuilderEndpointAPi,  } from '../../redux/sagas/apiBuilders'
// import { toast, Toaster } from 'react-hot-toast'

interface Country {
    name: string
    flag: string
}

const countries: { [key: string]: Country } = {
    us: { name: 'United States', flag: '🇺🇸' },
    ca: { name: 'Canada', flag: '🇨🇦' },
    uk: { name: 'United Kingdom', flag: '🇬🇧' },
}
const ENDPOINTSARRAY = [
    {
        "created_at": "2024-06-11T22:15:36.349692Z",
        "dependant": [
            {
                "name": "Phone Number (Advance)",
                "internal_param": "telephoneno",
                "external_key": "number",
                "code": "PHONE_VER_ADVANCE"
            }
        ],
        "updated_at": "2024-06-11T22:15:36.349688Z",
        "country": "Nigeria",
        "id": "DV_NIN",
        "local_function": "nin_v",
        "name": "National Identity Number",
        "request_pattern": {
            "number_nin": "12345678901"
        },
        "response_pattern": "https://idradar-bucket.s3.eu-west-2.amazonaws.com/sandbox-responses/vnin_success.json",
        "type": "Individual",
        "url": "internal/api/phone/advance/nin",
        "validation_class": "NINPortalSerializer"
    },
    {
        "created_at": "2024-06-11T22:06:55.608688Z",
        "dependant": [
            {
                "name": "National Identity Number",
                "internal_param": "nin",
                "external_key": "number_nin",
                "code": "DV_NIN"
            }
        ],
        "updated_at": "2024-06-11T22:06:55.608683Z",
        "country": "Nigeria",
        "id": "PHONE_VER_ADVANCE",
        "local_function": "custom_verification",
        "name": "Phone Number Advance",
        "request_pattern": {
            "number": "08082838283"
        },
        "response_pattern": "https://idradar-bucket.s3.eu-west-2.amazonaws.com/sandbox-responses/phone_number_advance_success.json",
        "type": "Individual",
        "url": "internal/api/phone/advance/nigeria",
        "validation_class": "NewPhoneNumberAdvanceSerializerIn"
    },
    {
        "created_at": "2024-06-11T21:44:00.765597Z",
        "dependant": [
            {
                "name": "Phone Number (Advance)",
                "internal_param": "phoneNumber1",
                "external_key": "number",
                "code": "PHONE_VER_ADVANCE"
            }
        ],
        "updated_at": "2024-06-11T21:44:00.765592Z",
        "country": "Nigeria",
        "id": "BVN",
        "local_function": "custom_verification",
        "name": "Bank Verification Number (Advance)",
        "request_pattern": {
            "number": "54651333604"
        },
        "response_pattern": "https://idradar-bucket.s3.eu-west-2.amazonaws.com/sandbox-responses/bvn_2.0_success.json",
        "type": "Individual",
        "url": "internal/api/bvn/advance",
        "validation_class": "BvnPortalSerializer"
    }
] 

interface ApiDetails {
    country: string
    endpointname: string
    inquiryType?: string
    endpoint?: string
    [key: string]: any
}
const schema = Yup.object({
    "name": Yup.string().required(),
    "country": Yup.string().required(),
    "request_pattern": Yup.mixed(
        // "National Identity Number": {"number_nin": "12345678901"},
    ),
    "response_pattern": Yup.mixed(),
    "type": Yup.string(),//"Individual"
    "display_endpoints": Yup.array().of(Yup.object({
        "id": Yup.string(),
        "name": Yup.string(),
        "relies_on":Yup.mixed(),
        "internal_param": Yup.mixed(),
        "external_key": Yup.mixed()
    })).required(),
    endpoints:Yup.array().of(Yup.object({
        "id": Yup.string(),
        "name": Yup.string(),
        "relies_on":Yup.mixed(),
        "internal_param": Yup.mixed(),
        "external_key": Yup.mixed(),
        isDependant:Yup.boolean()
    })).required(),
    step:Yup.number().required()
})
type createApiScemaType = Yup.InferType<typeof schema>

export default function CreateApi(props: any) {
    const location = useLocation()
    const { apiDetails } = location.state || {}

    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')
    // const [ENDPOINTSARRAY,setENDPOINTSARRAY] = useState([])

    const [code, setCode] = useState<string>('')
    const [selectedCountry, setSelectedCountry] = useState<string>('')
    // const [selectedEndpoints, setSelectedEndpoints] = useState<string[]>([])
    const [copyButtonText, setCopyButtonText] = useState<string>('Copy')

    const [link, setLink] = useState<string>('')
    const [jsonData, setJsonData] = useState<any>(null)





    const { 
        register,setValue, 
        handleSubmit,control,getValues,
        formState: { errors },watch,trigger,setError
      } = useForm<createApiScemaType>({ resolver: yupResolver(schema),defaultValues:{
        step:1 ,
        display_endpoints:[],
        endpoints:[]
   }});
   const step = watch('step');
   const country = watch('country');
   const formType = watch('type');
   const display_endpoints = watch('display_endpoints')
   const endpoints = watch('endpoints')
   const [responseStructure,setResponseStructure] = useState<{id:string,response:any}[]>([]);
   const [isLoadingResponse,setIsLoadingResponse] = useState(false)
   const [isCreating,setIsCreating] = useState(false)
   const alldata = watch()

   const handlePrevious = (): void => {
    // if (step > 1) {
    //     setStep(step - 1)
    // }
    if(step>1){
        setValue('step',step-1)
    }
}

    // const handleEndpointSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    //     const { value } = e.target
    //     if (value && !selectedEndpoints.includes(value)) {
    //         setSelectedEndpoints([...selectedEndpoints, value])
    //     }
    // }

    const handleRemoveEndpoint = (endpoint:any): void => {
        setValue('display_endpoints',display_endpoints.filter(d=>d.id!==endpoint.id))
        setValue('endpoints',endpoints.filter(d=>d.id!==endpoint.id))

        // @ts-ignore
        setResponseStructure([...responseStructure.filter(d=>d.id!==endpoint.id)])
    }

    // const formikStep1 = useFormik({
    //     initialValues: {
    //         country: apiDetails?.country || '',
    //         endpointname: apiDetails?.name || '',
    //     },
    //     validationSchema: Yup.object({
    //         country: Yup.string().required('Country is required'),
    //         endpointname: Yup.string().required('Endpoint Name is required'),
    //     }),
    //     onSubmit: (values) => {
    //         setStep(2)
    //     },
    // })

    // const formikStep2 = useFormik({
    //     initialValues: {
    //         inquiryType: apiDetails?.inquiryType || '',
    //         endpoint: apiDetails?.endpoint || '',
    //     },
    //     validationSchema: Yup.object({
    //         inquiryType: Yup.string().required('Inquiry Type is required'),
    //         endpoint: Yup.string().required('Endpoint is required'),
    //     }),
    //     onSubmit: (values) => {
    //         console.log('Form submitted:', values)
    //     },
    // })

    const getStepTitle = () => {
        switch (step) {
            case 1:
                return 'Step 1 of 2 - Select Country'
            case 2:
                return 'Step 2 of 2 - Endpoint'
            default:
                return ''
        }
    }

    const clearJsonData = () => {
        setJsonData(null)
    }

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputLink = event.target.value
    //     setLink(inputLink)
    //     fetchJsonData(inputLink)
    // }

    const fetchJsonData = (link: string) => {
        fetch(link)
            .then((response) => response.json())
            .then((data) => {
                setJsonData(data)
            })
            .catch((error) => {
                console.error('Error fetching JSON data:', error)
            })
    }

    const copyToClipboard = () => {
        if (jsonData) {
            navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2)).then(() => {
                setCopyButtonText('Copied')

                setNotifTitle('Success')
                setNotif('JSON copied to clipboard')
                setNotifVal(true)

                setTimeout(() => setCopyButtonText('Copy'), 2000)
            })
        }
    }
    const handleFirstLevelValidation =async ()=>{
        // console.log({validate:await trigger(['name','country'])})
        if(await trigger(['name','country']) === false){
            return 
        }

        // if all is well go to the next step
        setValue('step',2)

    }
    const handleSubmitData = async()=>{
        if(await trigger(['name','country','type','display_endpoints',])===false){
            return
        }
        const allValue = getValues();
        const request_pattern = {}
        const endpoints_id = endpoints.map(d=>d.id)
        endpoints_id.map(endID=>{
            ENDPOINTSARRAY.map(disp=>{
                if(endID===disp.id){
                    // @ts-ignore
                    request_pattern[disp.name]=disp.request_pattern
                }
            })
        })
        const submitData = {
            name:allValue.name,
            country:allValue.country,
            request_pattern:request_pattern,
            response_pattern:{},
            type:allValue.type,
            endpoints:endpoints
        }
        try{
            setIsCreating(true)
            const resp = await createApiBuilderEndpointAPi(submitData);
            console.log({resp})
            setNotifTitle('Success')
            setNotif('API template created successfully')
            setNotifVal(true)
            setIsCreating(false)
            
        }catch(err:any){
            setIsCreating(false)

            console.log('Something went wrogn',{err})
        }


    }
    const handleGetResponseJson = async (value:any)=>{
        setIsLoadingResponse(true)
        try{
            const resp = await axios.get(value.response_pattern)
            setResponseStructure([...responseStructure,{
            id:value.id,
            response:resp.data
            }])
            setIsLoadingResponse(false)
        }catch(err:any){
            setIsLoadingResponse(false)
        }
    }
    const handleDropDownEndpointAdd =(value:any)=>{
        const data= JSON.parse(value)
        if(endpoints.filter(d=>d.id ===data.id).length===0){
            setValue('display_endpoints',[...display_endpoints,data])
            setValue('endpoints',[...endpoints,{
                "id": data.id,
                "name":data.name,
                "relies_on": null,
                "internal_param": null,
                "external_key": null,
                isDependant:false
            }])

            handleGetResponseJson(data)
        }
    }
    const handleAddDependatEndpoint =(data:any)=>{
        if(endpoints.filter(d=>d.id ===data.code).length===0){
            setValue('endpoints',[...endpoints,{
                id:data.code,
                name:data.name,
                relies_on:data.relies_on,
                internal_param:data.internal_param,
                external_key:data.external_key,
                isDependant:true,
            }])
        }else{
            setValue('endpoints',endpoints.filter(d=>d.id!==data.code))
        }
       
    }

    // const getEndpoints =async()=>{
    //     try{
    //         const resp = await  getApiBuilderPremblyEndponts();
    //         setENDPOINTSARRAY(resp)
    //     }catch(err){

    //     }

    // }

    // useEffect(()=>{
    //     getEndpoints()
    // },[])
    console.log({errors})
    return (
        <div className="dashboard-area vh-100 mb-4">
            
            {notif && notifVal && (
                <NotificationToast
                    title={notifTitle}
                    message={notif}
                    closeNotif={() => setNotifVal(!notifVal)}
                />
            )}
            <div className="container-fluid h-100 px-md-4 my-4">
                <div className="pb-4">
                    <h5>Create New API</h5>
                </div>

                <div className="row h-100">
                    <div className="col-md-6 d-flex flex-column">
                        {step === 2 && (
                            <button className="greyBG go-back-btn" onClick={handlePrevious}>
                                <img src={Barrow} alt="Go Back" />
                                Go Back
                            </button>
                        )}
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="form-steps">
                                    <p className="greyBG sub-text">{getStepTitle()}</p>
                                    {/* <h5 className="sub-header">Parameters</h5>
                                    <p className="sub-text">
                                        We need you to select the necessary parameters to build your
                                        functional API
                                    </p> */}
                                </div>

                                <Form>
                                    {step === 1 && (
                                        // <Form onSubmit={formikStep1.handleSubmit}>
                                        <div >
                                            <div>
                                                <InputWithLabel 
                                                label='EndPoint Name'
                                                register={register('name')}
                                                errorMessage={errors.name?.message}
                                                />
                                                {/* <Form.Group controlId="endpointname" className="">
                                                    <Form.Label>EndPoint Name </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Endpoint Name"
                                                        required
                                                        value={formikStep1.values.endpointname}
                                                        onChange={formikStep1.handleChange}
                                                        onBlur={formikStep1.handleBlur}
                                                    />
                                                    {formikStep1.touched.endpointname &&
                                                    formikStep1.errors.endpointname ? (
                                                        <div className="error">
                                                            {formikStep1.errors.endpointname + ' *'}
                                                        </div>
                                                    ) : null}
                                                </Form.Group> */}
                                                <br />
                                                <div>
                                                    <h5 className="sub-header">Select Country</h5>
                                                </div>
                                                <p className="sub-text mb-">
                                                    Choose one or more countries to get endpoints
                                                    for your API
                                                </p>

                                                {/* <div
                                                    style={{
                                                        display: 'inline-block',
                                                    }}>
                                                    <img
                                                        src={worldIcon}
                                                        alt="worldIcon"
                                                        style={{
                                                            width: '24px',
                                                            height: '24px',
                                                            marginRight: '10px',
                                                        }}
                                                    />
                                                    <Form.Label htmlFor="country">
                                                        Country
                                                    </Form.Label>
                                                </div> 
                                                                                                <Form.Group className="mb-2">
                                                    <Form.Select
                                                        id="country"
                                                        name="country"
                                                        value={formikStep1.values.country}
                                                        onChange={formikStep1.handleChange}
                                                        onBlur={formikStep1.handleBlur}
                                                    >
                                                        <option value="">Select a country</option>
                                                        <option value="us">United States</option>
                                                        <option value="ca">Canada</option>
                                                        <option value="uk">United Kingdom</option>
                                                    </Form.Select>
                                                    {formikStep1.touched.country &&
                                                    formikStep1.errors.country ? (
                                                        <div className="error">
                                                            {formikStep1.errors.country + ' *'}
                                                        </div>
                                                    ) : null}
                                                </Form.Group>
                                                */}
                                                <SelectWithLabel
                                                label='Country'
                                                onChange={value=>{
                                                    setValue('country',value)
                                                }}
                                                
                                                options={
                                                //     [
                                                //     {name:'United States',value:'us'},
                                                //     {name:'Canada',value:'ca'},
                                                //     {name:'United Kingdom',value:'uk'},
                                                // ]
                                                Array.from(new Set(ENDPOINTSARRAY.map(d=>d.country))).map(country=>(
                                                    // @ts-ignore
                                                    {name:country,value:country}
                                                ))
                                            }
                                                errorMessage={errors.country?.message}
                                                />

                                            </div>

                                            <Button
                                                className="w-100 py-3 btn-light-green create-api mb-3"
                                                // type="submit"
                                                onClick={e=>{
                                                    e.preventDefault()
                                                    // setStep(2)
                                                    handleFirstLevelValidation()
                                                }}
                                                >
                                                Next
                                            </Button>
                                        </div>
                                    )}
                                    {step === 2 && (
                                        // <Form onSubmit={formikStep2.handleSubmit}>
                                        <div>
                                            <div>
                                                {/* <Form.Label htmlFor="details">Endpoint </Form.Label> */}
                                                {/* <p>
                                                    Choose one or more countries to get endpoints
                                                    for your API
                                                </p> */}
                                                {/* {selectedCountry && (
                                                    <div>
                                                        <p>
                                                            <img
                                                                alt="flag"
                                                                src={
                                                                    countries[selectedCountry].flag
                                                                }
                                                            />
                                                            {countries[selectedCountry].name}
                                                        </p>
                                                    </div>
                                                )} */}
                                                <div>
                                                    <p>
                                                        {country}
                                                    </p>
                                                </div>
                                                <div>
                                                    <h5>Select Use and Endpoint</h5>
                                                    <p>
                                                        Choose selected customer type for your API
                                                        and the endpoints
                                                    </p>
                                                </div>
                                                {/* <Form.Group className="mb-2 forms">
                                                    <Form.Label htmlFor="inquiryType">
                                                        <img src={userIcon} alt="userIcon" />
                                                        Inquiry Type
                                                    </Form.Label>
                                                    <Form.Select
                                                        id="inquiryType"
                                                        name="inquiryType"
                                                        value={formikStep2.values.inquiryType}
                                                        onChange={formikStep2.handleChange}
                                                        onBlur={formikStep2.handleBlur}
                                                    >
                                                        <option value="">
                                                            Select Inquiry Type
                                                        </option>
                                                        <option value="Customer">KYB</option>
                                                        <option value="Agent">KYC</option>
                                                    </Form.Select>
                                                    {formikStep2.touched.inquiryType &&
                                                    formikStep2.errors.inquiryType ? (
                                                        <div className="error">
                                                            {formikStep2.errors.inquiryType + ' *'}
                                                        </div>
                                                    ) : null}
                                                </Form.Group> */}
                                                {/* <InputWithLabel 
                                                label='Inquiry Type'
                                                icon={userIcon}
                                                /> */}
                                                <SelectWithLabel 
                                                label='Inquiry Type'
                                                icon={userIcon}
                                                options={[
                                                    {'name':'KYB',value:'Business'},
                                                    {'name':'KYC',value:'Individual'},
                                                ]}
                                                onChange={value=>{
                                                    setValue('type',value)
                                                }}
                                                />
                                                {/* {country}//{formType} */}
                                                <SelectWithLabel 
                                                label='Endpoint'
                                                icon={icon}
                                                options={ENDPOINTSARRAY.filter(endpoint=>{
                                                    // @ts-ignore
                                                    return endpoint.country === country && endpoint.type === formType
                                                })
                                                // .filter(d=>!endpoints.map(endpoint=>endpoint.id).includes(d.id))
                                                    // @ts-ignore
                                                .map(d=>({'name':d.name,value:JSON.stringify(d)}))}
                                                onChange={value=>{
                                                    console.log(JSON.parse(value),display_endpoints)
                                                    handleDropDownEndpointAdd(value)
                                                }}
                                                />
                                                {/* <Form.Group className="mb-2 forms">
                                                    <Form.Label htmlFor="endpoint">
                                                        <img src={icon} alt="icon" />
                                                        Endpoint
                                                    </Form.Label>
                                                    <Form.Select
                                                        id="endpoint"
                                                        name="endpoint"
                                                        value={formikStep2.values.endpoint}
                                                        onChange={(e) => {
                                                            formikStep2.handleChange(e)
                                                            handleEndpointSelect(e)
                                                        }}
                                                        onBlur={formikStep2.handleBlur}
                                                    >
                                                        <option value="">
                                                            Select your desired endpoints
                                                        </option>
                                                        <option value="Phone Number">
                                                            Phone Number
                                                        </option>
                                                        <option value="BVN">BVN</option>
                                                        <option value="NIN">NIN</option>
                                                        <option value="CAC">CAC</option>
                                                        <option value="International Passport">
                                                            International Passport
                                                        </option>
                                                    </Form.Select>
                                                    {formikStep2.touched.endpoint &&
                                                    formikStep2.errors.endpoint ? (
                                                        <div className="error">
                                                            {formikStep2.errors.endpoint + ' *'}
                                                        </div>
                                                    ) : null}
                                                </Form.Group> */}
                                                <div>
                                                    <h5 className="greyBG mt-3">
                                                        Selected Endpoints
                                                    </h5>
                                                    <div >
                                                        {display_endpoints?.map(
                                                            (display_endpoint, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="greyBGs endpoint-item"
                                                                    style={{'display':'inline-block'}}
                                                                    onClick={() =>{
                                                                        handleRemoveEndpoint(
                                                                            display_endpoint
                                                                        )
                                                                    }
                                                                    }>
                                                                    {display_endpoint.name}

                                                                    <img
                                                                        src={close}
                                                                        alt="close-btn"
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                        </div>
                                                </div>

                                                <h5 className="mt-4">
                                                    Verifiable IDS from Endpoints
                                                </h5>
                                                <p>
                                                    Select extra Verifiable IDs from the endpoints
                                                    you choose
                                                </p>
                                                <div  className="selected-endpoints">
                                                {/* // @ts-ignore */}

                                                    {
                                                        // @ts-ignore
                                                        display_endpoints.map(d=>{return d?.dependant.map(dep=>({...dep,relies_on:d.name}))}).flat()
                                                        .filter(filtD=>{
                                                            const selectedDisplayEndpointIDs = display_endpoints.map(d=>d.id)
                                                            // console.log({selectedDisplayEndpointIDs,eachDependat:filtD.id})
                                                            return !selectedDisplayEndpointIDs.includes(filtD.code)
                                                        })
                                                        .reduce((obj,current)=>{
                                                            const x = obj.find((item:any) => item.name === current.name);
                                                            if(!x){
                                                                return obj.concat([current]);
                                                            }else{
                                                                return obj
                                                            }
                                                        },[]).map((d:any)=>(
                                                    <p 
                                                    onClick={()=>{
                                                        
                                                        handleAddDependatEndpoint(d)
                                                    }}
                                                    style={
                                                        endpoints.filter(endpoint=>endpoint.isDependant==true&&endpoint.id==d.code).length==0?
                                                        {
                                                      
                                                        'backgroundColor':'#F4F4F4','color':'#2A2A2A',
                                                    padding:'8px 10px',borderRadius:'40px',display:'inline-block',cursor:'pointer'}:{
                                                        'backgroundColor':'#B1E2DB','color':'#23766E',
                                                        padding:'8px 10px',borderRadius:'40px',display:'inline-block',cursor:'pointer'
                                                    }}>
                                                        {d.name}
                                                    </p>
                                                            
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <Button
                                                // className="py-3 btn-light-green create-api mb-3"
                                                className="w-100 py-3 btn-light-green create-api mb-3"
                                                onClick={e=>{
                                                    e.preventDefault()
                                                    handleSubmitData()
                                                }}
                                                type="submit">
                                                    {
                                                        isCreating?
                                                        <Spinner/>:
                                                        'Create API Link'
                                                    }
                                                
                                            </Button>
                                            {/* <Button className="py-3 btn-grey create-api mb-3"
                                            onClick={e=>{

                                            }}
                                            >
                                                Save as Draft
                                            </Button> */}
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-5">
                        <div className="card w-100 px-3">
                            <h5 className="sub-header">Results</h5>

                            <div>
                                <Form.Group style={{ position: 'relative' }}>
                                    <Form.Label htmlFor="apiLink">API Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="link"
                                        value={link}
                                        placeholder="Your API link will appear here"
                                        // onChange={handleInputChange}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '70%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={copyToClipboard}>
                                        <img
                                            src={copy}
                                            alt="copy icon"
                                            style={{ background: 'white' }}
                                        />
                                    </span>
                                </Form.Group>
                            </div>

                            <div className="response-structure">
                                <div className="resStru">
                                    <h5>RESPONSE STRUCTURE</h5>
                                </div>

                                <div className="json-container">
                                    <p>//Your API ;-)</p>

                                    <Button
                                        onClick={copyToClipboard}
                                        variant="link"
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                        }}>
                                        <span className="copy-icon">
                                            {copyButtonText}
                                            <img
                                                src={copy}
                                                alt="copy icon"
                                                style={{ marginLeft: '3px', filter: 'invert(1)' }}
                                            />
                                        </span>
                                    </Button>
                                        {
                                            isLoadingResponse?
                                            <p>Loading Response</p>:''
                                        }
                                    {/* {!jsonData ? ( */}
                                    {false ? (
                                        <div className="placeholders">
                                            <img src={codee} alt="codee" />
                                            <br />
                                            Your code will display here
                                            <br />
                                            When you have generated your API, it will appear here
                                        </div>
                                    ) : (
                                        <div
                                        //  className="json"
                                        style={{'color':'orange'}}
                                        >

                                            {
                                                isLoadingResponse?
                                                <Spinner style={{'display':'block','margin':'0 auto'}} />:
                                                <pre>{JSON.stringify(responseStructure,null,2)}</pre>

                                            }
                                            
                                            {/* <pre>{JSON.stringify(display_endpoints, null, 2)}</pre> */}
                                            {/* <pre>{JSON.stringify(endpoints, null, 2)}</pre> */}
                                            {/* <pre>{JSON.stringify(jsonData, null, 2)}</pre> */}
                                        </div>
                                    )}
                                    <Button
                                        onClick={clearJsonData}
                                        variant="link"
                                        className="clearBtn">
                                        <img
                                            src={deletee}
                                            alt="delete icon"
                                            style={{
                                                marginRight: '7px',
                                            }}
                                        />
                                        Clear Input
                                    </Button>
                                </div>
                                <div>
                                    {/* {JSON.stringify(endpoints)} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
