import { ReportAnalysisHistoryState, ReportAnalysisState } from '../../actions/aiReportAnalysis/types';
import { actionTypes } from './../../constants/actionTypes';

let initialState : ReportAnalysisState = {
    isLoading: false,
    error: null,
    resp:null,
};
let historyInitialState : ReportAnalysisHistoryState = {
    isLoading: false,
    error: null,
    resp:null,
};

export const reportAnalysisReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.REPORT_ANALYSIS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.REPORT_ANALYSIS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp:action.payload.resp
            };
        case actionTypes.REPORT_ANALYSIS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};


export const reportAnalysisHistoryReducer = (state = historyInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case actionTypes.REPORT_ANALYSIS_HISTORY_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.REPORT_ANALYSIS_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resp:action.payload.resp
            };
        case actionTypes.REPORT_ANALYSIS_HISTORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};