import React from 'react'

// Define props type for DeleteModal
interface DeleteModalProps {
    isOpen: boolean
    onClose: () => void
    onDelete: () => void
}

// Modal component for delete confirmation
export const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onDelete }) => {
    if (!isOpen) return null

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="close-con">
                    <span className="close" onClick={onClose}>
                        &times;
                    </span>
                </div>
                <div className="underline"></div>
                <h2>Delete API</h2>
                <p>
                    Are you sure you want to delete this API? To recover this API, you will need to
                    recreate it with all its previous endpoints.
                </p>
                <button
                    className="btn btn-light-green w-100 py-3 create-api mb-3"
                    onClick={onDelete}>
                    Yes, delete API
                </button>
                <button className="btn btn-grey" onClick={onClose}>
                    No, Go back
                </button>
            </div>
        </div>
    )
}
