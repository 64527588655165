import React, { useState } from 'react';
import './RangeSlider.css';

const RangeSlider = ({onChange}:{onChange:(value:number)=>void}) => {
  const [value, setValue] = useState(85);

  const handleChange = (event:any) => {
    // console.log(event.target.value)
    setValue(event.target.value);

    if(onChange){
      onChange(event.target.value)
    }
  };

  return (
    <div className="slider-container">
      <div style={{'position':'relative',
    // 'border':'1px solid red',
    }}>
        <span style={{'display':'block','height':'4px','backgroundColor':'#2F9D92','width':`${value}%`,
        transform:'translateY(18px)',
        // zIndex:'300'
        }}></span>
      <input
        type="range"
        min="0"
        max="100"
        step={20}
        value={value}
        className="slider"
        onChange={handleChange}
      />
      {value}
      </div>
    </div>
  );
};

export default RangeSlider;
