import React, { useEffect, useState } from 'react'
import { SdkWandIcon } from '../../../../../assets/svgs'
import { useDispatch, useSelector } from 'react-redux'
import {
    sdkBiometricsInfoRequest,
    sdkEndpointsInfoRequest,
} from '../../../../../redux/actions/sdkLibraries'

export default function BiometricsComp() {
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')

    const biometricsInfoState = useSelector((state: any) => state.sdkBiometricsInfoReducer)

    const dispatch = useDispatch()

    useEffect(() => {
        getBiometricsInfo()
    }, [])

    let getBiometricsInfo = () => {
        const callback = (data: any) => {
            console.log(data)
            if (data?.status) {
                // updateFields()
            } else {
                setNotifTitle('Error')
                setNotif(data?.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(sdkEndpointsInfoRequest(data))
    }

    // console.log(biometricsInfoState)
    return (
        <div>
            <div className="rounded-3 p-3" style={{ background: '#FFF2E6' }}>
                <span>{SdkWandIcon}</span>
                <p>
                    Please note that this is currently experimental in nature and may fail.
                    Selections made below will be randomised to your users when trying to do their
                    facial verifications
                </p>
            </div>

            <div>
                <h6 className="mt-3">Face Dynamism</h6>
                <p className="my-5"></p>
            </div>

            <div>
                <h6>Face Confidence Level</h6>
                <p className="mt-2">Default confidence level is 85%</p>
            </div>
        </div>
    )
}
