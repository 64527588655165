const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}` 
const appBaseUrl = `${window.location.origin+"/"}`
// const appBaseUrl = `${process.env.REACT_APP_APP_BASE_URL}`
const liveUrl = `${process.env.REACT_APP_API_LIVE_URL}`
const idpassApiUrl = `${process.env.REACT_APP_API_IDENTITYPASS_URL}`
const idradarApiUrl = `${process.env.REACT_APP_API_IDENTITYRADAR_URL}`
const backgroundCheckApiUrl = `${process.env.REACT_APP_API_BACKGROUNDCHECK_URL}`
const reportUrl = `${process.env.REACT_APP_API_REPORTBILLING_URL}`


// const apiBaseUrl = `https://api.prembly.com/` 
// const appBaseUrl = `https://dashboard.prembly.com/`
// const liveUrl = `prembly/`
// const idpassApiUrl = `identitypass/`
// const idradarApiUrl = `identityradar/`


const global = {apiBaseUrl, appBaseUrl, liveUrl, idpassApiUrl, idradarApiUrl, backgroundCheckApiUrl, reportUrl};

export default global 